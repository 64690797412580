export type TTestData = {
    _id: string;
    name: string;
    status: ETestStatus;
    createdAt: number;
    totalQuestions: number;
    skills: string[]
    position: string;
    title?: string;
}

export enum ETestStatus {
    DRAFT = 'draft',
    APPROVED = 'approved',
    REJECTED = 'rejected',
    SUBMITTED = 'submitted',
    ACTIVE = 'active',
    CANCELLED = 'cancelled'
}

export const testStatusMapping: Record<ETestStatus, string> = {
    [ETestStatus.DRAFT]: 'Nháp',
    [ETestStatus.APPROVED]: 'Đã duyệt',
    [ETestStatus.REJECTED]: 'Trả về',
    [ETestStatus.SUBMITTED]: 'Đã gửi',
    [ETestStatus.ACTIVE]: 'Niêm yết',
    [ETestStatus.CANCELLED]: 'Bị hủy'
}

export const StatusTestMappingColor = {
    [ETestStatus.ACTIVE]: '#1C92FF',
    [ETestStatus.DRAFT]: '#B3B1B8',
    [ETestStatus.APPROVED]: '#0BAA60',
    [ETestStatus.REJECTED]: '#E09400',
    [ETestStatus.SUBMITTED]: '#0BAA60',
    [ETestStatus.CANCELLED]: '#F95E5E'
}
