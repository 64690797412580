import React from "react";
import { useAssementData } from "../../hooks/useCreateAssessment"
import { SectionAssessment } from "../add-additional-test/components/section";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReviewQuestionRow } from "./row-question";
import { TAdditionalQuestion, TReviewQuestion } from "../../type";
import { uuid } from "../../../../create_test/tab/question/helper";
import { ILibrary } from "../../../../library/models";
import { reorder } from "../../../../../utils";
import _, { divide } from "lodash";

const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    width: '100%'
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "#F1E8FD" : "white",
    color: isDragging ? "#46485C" : "inherit",
    borderRadius: isDragging ? "8px" : "inherit",
    border: isDragging ? "1px solid #7357FF" : "inherit",
    boxShadow: isDragging ? "0px 4px 16px rgba(79, 117, 140, 0.24)" : "inherit",
    ...draggableStyle
});

export const ReviewConfigureAssessment: React.FC = () => {
    const { assessment, additionalQuestions, setAdditionalQuestions, tests, setTests, reviewQuestion, setReviewQuestion } = useAssementData();
    React.useEffect(() => {
        const currentTests = reviewQuestion.filter(d => d.type === 'question').map(d => d.data);
        const testsValues = tests?.filter(d => d._id);
        if (tests.length && !_.isEqual(currentTests, testsValues)) {
            const testQuestion: TReviewQuestion[] = tests?.filter(d => d._id).map((item: ILibrary) => {
                const question: TReviewQuestion = {
                    id: uuid(),
                    type: 'question',
                    data: item,
                }
                return question;
            });
            const testQuestionOther = reviewQuestion.filter(d => d.type !== 'question');
            const testQuestions = reviewQuestion.filter(d => d.type === 'question');
            setReviewQuestion(prev => {
                const newTestQuestion = [...testQuestion];
                const indexAdditionalQuestions = prev.findIndex(d => d.type === 'additional_question');
                if (indexAdditionalQuestions === -1 || testQuestions.length === 0) {
                    newTestQuestion.push(...testQuestionOther);
                } else {
                    newTestQuestion.splice(indexAdditionalQuestions, 0, ...testQuestionOther);
                }
                return newTestQuestion;
            });
        }
    }, [tests, reviewQuestion, assessment, setReviewQuestion]);

    React.useEffect(() => {
        if (additionalQuestions.length) {
            const additionalQuestionList: TReviewQuestion[] = additionalQuestions?.map((item: TAdditionalQuestion) => {
                const question: TReviewQuestion = {
                    id: uuid(),
                    type: 'additional_question',
                    data: item,
                }
                return question;
            });
            const newData = {
                ...additionalQuestionList[0].data,
                title: `Câu hỏi bổ sung (${additionalQuestionList.length})`,
                duration: additionalQuestionList?.map(d => d.data.duration || 0)?.reduce((a, b) => a + b, 0),
            };
            const additionalQuestionGroup: TReviewQuestion[] = [
                {
                    id: uuid(),
                    type: 'additional_question',
                    data: newData
                }
            ];
            setReviewQuestion(prev => {
                const cloned = [...prev];
                const indexAdditionalQuestions = prev.findIndex(d => d.type === 'additional_question');
                if (indexAdditionalQuestions === -1) {
                    cloned.splice(assessment.additionalIndex || prev.length, 0, ...additionalQuestionGroup);
                    return cloned;
                } else {
                    cloned[indexAdditionalQuestions].data = newData;
                    return cloned;
                }
            })
        }

    }, [additionalQuestions]);

    // Drop && Drag
    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        const newData = reorder(
            reviewQuestion,
            source.index,
            destination.index
        );
        setReviewQuestion(newData);
        const newTests = newData.filter(d => d.type === 'question').map(d => d.data._id);
        setTests(prev => {
            return [...prev].sort((a, b) => {
                if (a._id && b._id) return newTests.indexOf(a._id) - newTests.indexOf(b._id);
                return a._id ? -1 : b._id ? 1 : 0
            })
        });
    };

    const onDeleteTest = React.useCallback((index: number) => {
        const reviewQuestionType = reviewQuestion[index].type;
        if (reviewQuestionType === 'question') {
            setTests(prev => {
                const newTest = prev.map(({ _id, ...others }) => {
                    if (_id === reviewQuestion[index].data._id) return {
                        ...others,
                    }
                    return {
                        _id,
                        ...others
                    }
                });
                newTest.sort((a: any, b: any) => a._id ?
                    b._id ? 0 : -1
                    : b._id ? 1 : 0
                );
                return newTest;
            });
        }
        if (reviewQuestionType === 'additional_question') {
            setAdditionalQuestions([]);
        }
        setReviewQuestion(prev => prev.filter((_, i) => i !== index));
    }, [reviewQuestion, setReviewQuestion, setTests, setAdditionalQuestions]);

    const reviewQuestionList = React.useMemo(() => {
        return reviewQuestion.filter(d => d !== undefined).map((item, index) => {
            return (
                <Draggable
                    key={`${index}_${item.id}`}
                    draggableId={`id_${item.id}_${index}`}
                    index={index}
                >
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getMoveItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                            )}
                        >
                            <ReviewQuestionRow
                                question={item}
                                index={index}
                                key={item.id}
                                onDelete={onDeleteTest}
                            />
                        </div>
                    )}
                </Draggable>
            )
        });
    }, [reviewQuestion, onDeleteTest]);

    return (
        <div className="space-y-12">
            <div className="p-2 lg:p-8 pt-0 grid grid-cols-1 gap-[48px]">
                <SectionAssessment
                    title="Xác nhận các đề kiểm tra trước khi hoàn tất"
                    subTitle="Bạn vẫn có thể thay đổi bài đánh giá cho đến khi bạn mời ứng viên đầu tiên."
                    content={
                        <div className="overflow-x-auto">
                            <div className="min-w-[700px] grid grid-cols-1 mt-[24px] gap-[12px] rounded-[12px] py-[16px] bg-white">
                                <div className="grid grid-cols-[35px_1fr_200px_250px] justify-start items-center pl-[55px] font-bold text-[16px] leading-[19px]">
                                    <span>No.</span>
                                    <span>Tên đề kiểm tra</span>
                                    <span>Thời lượng</span>
                                    <span>Đơn giá</span>
                                </div>
                                <div>
                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="droppable">
                                            {(provided, snapshot) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                    style={getListStyle(snapshot.isDraggingOver)}
                                                >
                                                    {reviewQuestionList}
                                                    {provided.placeholder}
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    );
};

