import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ICCheckFill } from '../../icons';
import { HRButton } from '../../components/button';
import { PaymentMethosMapping } from '../payment/type';
import { formatMoney } from '../../utils';
import { formatLocaleDateString } from '../../utils/date';
import { usePaymentStatusPageContext } from '.';


export const PaymentSuccessCandidateCreditPage: React.FC = () => {
    const navigate = useNavigate();
    const { orderDetails } = usePaymentStatusPageContext();

    return <div className="rounded-[12px] bg-white shadow-l-large flex w-full lg:max-w-[562px] flex-col items-center justify-center gap-[32px] p-4 lg:p-8">
        <ICCheckFill fill='#0BAA60' width={100} height={100} />
        <div className="flex flex-col gap-[24px]">
            <h1 className="text-[28px] mb-[0] font-semibold text-center leading-[40px]">Thanh toán thành công</h1>
            <div className="flex flex-col gap-[4px]">
                <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Bạn đã có thêm <strong>{orderDetails?.difinition?.totalCandidateCredit}</strong> lượt đánh giá ứng viên sau khi thanh toán thành công.
                </h4>
                {orderDetails?.hasInvoice && <h4 className="text-[14px] mb-[0] leading-[24px] font-normal text-center">
                    Hóa đơn của bạn đã được gửi về email: <strong>{orderDetails?.invoiceInfo?.buyerEmail}</strong>
                </h4>}
            </div>
            <div className="flex flex-col gap-[24px] rounded-[12px] border border-[#ECECED] p-[24px] ">
                <div className="flex justify-between items-center">
                    <span className="text-[14px] leading-[24px] text-high-em font-normal">Lượt đánh giá ứng viên</span>
                    <span className="text-[14px] leading-[24px] text-high-em font-bold">
                        {orderDetails?.difinition?.totalCandidateCredit}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-[14px] leading-[24px] text-high-em font-normal">Ngày</span>
                    <span className="text-[14px] leading-[24px] text-high-em font-bold">
                        {formatLocaleDateString(orderDetails?.paidAt as number)}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-[14px] leading-[24px] text-high-em font-normal">Mã đơn</span>
                    <span className="text-[14px] leading-[24px] text-high-em font-bold">
                        {orderDetails?.orderCode}
                    </span>
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-[14px] leading-[24px] text-high-em font-normal">Phương thức</span>
                    {orderDetails?.paymentMethod && <span className="text-[14px] leading-[24px] text-high-em font-bold">
                        {PaymentMethosMapping[orderDetails?.paymentMethod]}
                    </span>}
                </div>
                <div className="flex justify-between items-center">
                    <span className="text-[14px] leading-[24px] text-high-em font-normal">Tổng cộng</span>
                    {orderDetails?.pricing?.totalPrice && <span className="text-[14px] leading-[24px] text-high-em font-bold">
                        {formatMoney(orderDetails?.pricing?.totalPrice as number)}
                    </span>}
                </div>
            </div>
            <div className="flex items-center justify-center">
                <span>
                    <HRButton btnType="secondary"
                        onClick={() => navigate(`/my-assessment`)}
                    >
                        Trở về
                    </HRButton>
                </span>
            </div>
        </div>
    </div>
}
