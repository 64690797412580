import React from "react";
import { HRButton, IconButton } from "../../../components/button";
import { ICCopy, ICDownload, ICUserV2, ICEdit, ICDelete } from "../../../icons";
import { createSearchParams } from "react-router-dom";
import { ICBack } from '../../../icons/back';
import { useAssementDetailsData } from ".";
import { cloneAssessment, deleteAssessment } from "../../../api/assessment";
import { useNotification } from "../../../hooks/useNotification";
import { EStatusAssessment, StatusAssessmentMapping, StatusAssessmentMappingColor } from "../type";
import { useGeneralPageData } from "../../general";
import { useNavigation } from "../../../hooks/useNavigate";
import { Modal, Tag } from "antd";
import { useSticky } from "../../../hooks/useSticky";
import classNames from 'classnames';
import styled from "styled-components";
import { HRTooltip } from "../../../components/tooltip";
import { useScrollContent } from "../../../hooks/useScrollContent";
import { useMobile } from "../../../hooks/useDimensions";

type WrapperProps = {
    isMobile: boolean;
}
const Wrapper = styled(styled.div``) <WrapperProps>`
    position: ${props => props.isMobile ? 'relative' : 'sticky'};
    &.header-assessment-details-sticky {
        transition: transform 150ms ease-in-out;
        top: 70px;
        left: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-sizing: border-box;
        padding-top: 4px;
        .none-sticky {
            display: none;
        }
        .sticky-content {
            max-width: 100%;
            padding: 8px 12px;
        }
    }
    &.nav-bar--hidden {
        transform: translateY(-100%);
        box-shadow: none !important;
    }
`;

type HeaderAssessmentDetailsProps = {
    id: string;
    onDownload: () => void;
}

export const HeaderAssessmentDetails: React.FC<HeaderAssessmentDetailsProps> = ({
    id,
    onDownload
}) => {
    const scrollData = useScrollContent();
    const { isSticky, element } = useSticky();
    const { navigate, getBackUrl } = useNavigation();
    const { setShowComingSoonModal } = useGeneralPageData();
    const { showSuccess, showError } = useNotification();
    const {
        assessmentDetails,
        candidateList,
        activeDownloadResult,
    } = useAssementDetailsData();
    const [isProcessing, setIsProcessing] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [navClassList, setNavClassList] = React.useState<string[]>([]);
    const isMobile = useMobile();
    React.useEffect(() => {
        if (isMobile) return;
        const _classList = [];
        if (scrollData.y > 150 && scrollData.y - scrollData.lastY > 0) {
            _classList.push("nav-bar--hidden");
        }
        setNavClassList(_classList);
    }, [isMobile, scrollData.y, scrollData.lastY]);

    const onBackAction = React.useCallback(() => {
        const referrer = getBackUrl();
        let searchParams = new URLSearchParams();
        if (referrer) {
            const url = new URL(referrer);
            if (url.pathname === '/my-assessment') {
                searchParams = new URLSearchParams(url.search);
            }
        }
        searchParams.append('status', assessmentDetails?.status || EStatusAssessment.ACTIVE);
        navigate({
            pathname: "/my-assessment",
            search: searchParams.toString()
        });
    }, [navigate, assessmentDetails, getBackUrl]);

    const onEditAction = React.useCallback(() => {
        navigate(`/edit-assessment/${id}`);
    }, [navigate, id]);

    const onDeleteAssessment = React.useCallback(async () => {
        try {
            await deleteAssessment(id);
            showSuccess('Xóa thành công!');
            navigate({
                pathname: "/my-assessment",
                search: createSearchParams({
                    status: assessmentDetails?.status || EStatusAssessment.ACTIVE,
                }).toString()
            });
        } catch (error: any) {
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
    }, [id, showSuccess, showError, navigate, assessmentDetails]);

    const onCloneAssessment = React.useCallback(async () => {
        try {
            if (isProcessing) return;
            setIsProcessing(true);
            await cloneAssessment(id);
            showSuccess('Sao chép thành công!');
            navigate({
                pathname: "/my-assessment",
                search: createSearchParams({
                    status: assessmentDetails?.status || EStatusAssessment.ACTIVE,
                }).toString()
            });
            setIsProcessing(false);
        } catch (error: any) {
            showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
        }
    }, [id, navigate, isProcessing, assessmentDetails, showSuccess, showError]);

    const totalTests = React.useMemo(() => {
        if (assessmentDetails?.tests) {
            return assessmentDetails?.tests.filter(d => typeof d._id === 'string').length;
        }
        return 0;
    }, [assessmentDetails]);

    const totalAdditionalQuestions = React.useMemo(() => {
        if (assessmentDetails?.tests) {
            return assessmentDetails.additionalQuestions.length;
        }
        return 0;
    }, [assessmentDetails]);

    const totalTime = React.useMemo(() => {
        let timeQuestion = 0;
        let timeAdditional = 0;
        if (assessmentDetails?.tests) {
            const durations = assessmentDetails.tests.filter(d => typeof d._id === 'string').map(t => {
                const questionDuration = t.configuration?.questionDuration ?? 0;
                if (Array.isArray(questionDuration)) {
                    return questionDuration.reduce((acc, cur) => acc + cur ?? 0, 0);
                }
                return questionDuration
            })
            timeQuestion = durations.reduce((acc, cur) => {
                return acc + cur
            }, 0);
        }
        if (assessmentDetails?.additionalQuestions) {
            timeAdditional = assessmentDetails.additionalQuestions.map(d => d.duration).reduce((partialSum, a) => (partialSum || 0) + (a || 0), 0) || 0;
        }
        return timeQuestion + timeAdditional;
    }, [assessmentDetails]);

    const activeEditButton = React.useMemo(() => {
        if (!assessmentDetails) return false;
        if (assessmentDetails?.status === EStatusAssessment.ACTIVE || assessmentDetails?.status === EStatusAssessment.PAIDING) return false;
        if (assessmentDetails?.isPaid && assessmentDetails?.needToPay) return false;
        return assessmentDetails?.status !== EStatusAssessment.ARCHIVED && assessmentDetails?.totalCandidates === 0;
    }, [assessmentDetails]);

    const activeDownloadButton = React.useMemo(() => {
        if (!assessmentDetails) return false;
        if (assessmentDetails?.status === EStatusAssessment.LOCKED) return false;
        if (assessmentDetails?.status !== EStatusAssessment.DRAFT) return true;
    }, [assessmentDetails]);

    return <Wrapper
        isMobile={isMobile}
        ref={element}
        className={classNames([
            "text-left lg-container z-[4]",
            isSticky && !isMobile ? "header-assessment-details-sticky shadow-e-03 box-border" : "",
            ...navClassList
        ])}
    >
        <div className="lg-container gap-4 lg:flex items-center justify-between sticky-content">
            <div className="flex flex-col items-start gap-[8px]">
                <div className="flex flex-row items-center gap-[16px]">
                    <div className="flex items-start">
                        {!isSticky && <IconButton btnType="sub" btnSize="sm"
                            onClick={onBackAction}
                            icon={<ICBack />}
                        />}
                        {isSticky && <HRButton btnSize="sm" btnType="sub" onClick={onBackAction}>
                            <div className="flex items-center gap-[8px]">
                                <ICBack />
                                <span className="text-body-medium text-high-em">
                                    Trở về
                                </span>
                            </div>
                        </HRButton>}
                    </div>
                    <div className="grid grid-cols-1 gap-[8px]">
                        <div className="max-w-[500px] line-clamp-1 text-high-em text-heading-6-medium">
                            {assessmentDetails?.title}
                        </div>
                    </div>
                    <div className="flex gap-[16px]">
                        {
                            (assessmentDetails?.status as EStatusAssessment) === EStatusAssessment.LOCKED && <Tag style={{
                                borderRadius: '4px',
                                fontSize: '12px',
                                fontWeight: 500,
                                lineHeight: '16px',
                                color: '#4F4B5C',
                                borderColor: '#ECECED',
                                padding: '4px 8px',
                                margin: 0,
                                backgroundColor: StatusAssessmentMappingColor[assessmentDetails?.status as EStatusAssessment],
                            }}>
                                {StatusAssessmentMapping[assessmentDetails?.status as EStatusAssessment]}
                            </Tag>
                        }
                        {(assessmentDetails?.status as EStatusAssessment) !== EStatusAssessment.LOCKED && <Tag style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: (assessmentDetails?.status as EStatusAssessment) === EStatusAssessment.LOCKED ? '#4F4B5C' : '#FFFFFF',
                            padding: '4px 8px',
                            margin: 0,
                            backgroundColor: assessmentDetails?.status === EStatusAssessment.PAIDING ? StatusAssessmentMappingColor[EStatusAssessment.DRAFT] : StatusAssessmentMappingColor[assessmentDetails?.status as EStatusAssessment],
                            borderColor: assessmentDetails?.status === EStatusAssessment.PAIDING ? StatusAssessmentMappingColor[EStatusAssessment.DRAFT] : StatusAssessmentMappingColor[assessmentDetails?.status as EStatusAssessment]
                        }}>
                            {assessmentDetails?.status === EStatusAssessment.PAIDING ? StatusAssessmentMapping[EStatusAssessment.DRAFT] : StatusAssessmentMapping[assessmentDetails?.status as EStatusAssessment]}
                        </Tag>}
                        {(assessmentDetails?.status as EStatusAssessment) === EStatusAssessment.PAIDING && <Tag style={{
                            borderRadius: '4px',
                            fontSize: '12px',
                            fontWeight: 500,
                            lineHeight: '16px',
                            color: '#E09400',
                            padding: '4px 8px',
                            margin: 0,
                            backgroundColor: StatusAssessmentMappingColor[assessmentDetails?.status as EStatusAssessment],
                            borderColor: StatusAssessmentMappingColor[assessmentDetails?.status as EStatusAssessment]
                        }}>
                            {StatusAssessmentMapping[assessmentDetails?.status as EStatusAssessment]}
                        </Tag>}
                    </div>
                </div>
                <div className="grid grid-cols-[60px_1fr] none-sticky">
                    <div></div>
                    <div className="grid grid-cols-[auto_auto_auto] gap-[16px] justify-start text-med-em text-sub-medium">
                        <span>{totalTests} đề kiểm tra</span>
                        <span>{`${totalAdditionalQuestions} câu hỏi`}</span>
                        <span>{`${totalTime} phút`}</span>
                    </div>
                </div>
            </div>

            <div className="flex gap-[16px] pt-2 lg:pt-0 px-4 lg:px-0 none-sticky">
                {activeEditButton && <IconButton
                    btnSize="sm"
                    btnType="sub"
                    onClick={onEditAction}
                    icon={<ICEdit width={18} height={18} />}
                    hoverText="Chỉnh sửa bài đánh giá"
                />}
                {assessmentDetails?.status !== EStatusAssessment.PAIDING && <IconButton
                    btnSize="sm"
                    btnType="sub"
                    onClick={onCloneAssessment}
                    icon={<ICCopy width={18} height={18} />}
                    hoverText="Sao chép bài đánh giá"
                />}
                <IconButton
                    btnSize="sm"
                    btnType="sub"
                    onClick={() => setShowComingSoonModal(true)}
                    icon={<ICUserV2 width={18} height={18} />}
                />
                {(assessmentDetails?.status === EStatusAssessment.DRAFT || assessmentDetails?.status === EStatusAssessment.ARCHIVED) && <IconButton
                    onClick={() => setShowDeleteModal(true)}
                    btnSize="sm"
                    btnType="sub"
                    icon={<ICDelete width={18} height={18} />}
                    hoverText="Xóa bài đánh giá"
                />}
                {activeDownloadResult && activeDownloadButton && <IconButton
                    btnSize="sm"
                    btnType="sub"
                    onClick={() => onDownload()}
                    icon={<ICDownload width={18} height={18} />}
                    hoverText="Tải kết quả tất cả ứng viên"
                />}
                {!activeDownloadResult && <HRTooltip content="Chưa có ứng viên nào hoàn thành"
                    placement={'top'}
                    mouseLeaveDelay={0}
                    childNode={
                        <span style={{ cursor: !activeDownloadResult ? 'not-allowed' : 'pointer' }}>
                            <IconButton
                                btnSize="sm"
                                btnType="sub"
                                disabled
                                style={{
                                    pointerEvents: 'none',
                                    height: '40px',
                                    width: '40px',
                                    display: 'grid',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '8px',
                                    border: 0
                                }}
                                icon={<ICDownload width={18} height={18} fill="var(--icon-disabled)" />}
                            />
                        </span>
                    }>
                </HRTooltip>}
            </div>
        </div>
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            closable={false}
            title={
                <div className="text-title-bold text-high-em">Xóa bài đánh giá</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnType="danger"
                        btnSize="sm"
                        onClick={() => {
                            onDeleteAssessment();
                            setShowDeleteModal(false);
                        }}>
                        Xóa
                    </HRButton>
                </div>
            }>
            <p className="mb-0 text-standard-medium text-med-em">
                Bạn chắc chắn muốn loại bỏ bài đánh giá này? Mọi nội dung bạn đã tạo trong bài đánh giá cũng sẽ bị mất đi sau khi thực hiện thao tác xóa này.
            </p>
        </Modal>
    </Wrapper>
}
