import React from "react"
import { ICUndo } from "../../icons/index";
import { HRButton } from "../../components/button"
import { HRPagination } from "../../components/pagination"
import { Col, Row, PaginationProps, Button } from 'antd';
import { LibraryCard } from "./card"
import { LibraryNoData } from "./no_data_page";
import { ILibrary, IPagination, ISearchCriteria, TRatingLevel } from './models';
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/reducers'
import _ from "lodash";
import { useScroll } from "../../hooks/useScroll";
import { getTestRatingLevel } from "../../api/library";
import { useMobile } from "../../hooks/useDimensions";

type LibraryListProps = {
    data?: ILibrary[];
    pagination?: IPagination,
    keyword?: string,
    onReloadData: () => void;
    onChange: (value: any) => void;
    showResult?: boolean;
    pagingMode?: 'pagination' | 'infinite',
    itemRender?: React.FC<any>;
    filterParams?: ISearchCriteria;
}

const PX_NEED_TO_LOAD_NEXT_PAGE = 70;
export const LibraryList: React.FC<LibraryListProps> = ({
    data = [],
    pagination,
    keyword,
    onReloadData,
    onChange,
    showResult = true,
    pagingMode = 'pagination',
    itemRender,
    filterParams
}) => {
    const isMobile = useMobile();
    const isReachEnd = React.useMemo(() => {
        return pagination && pagination.totalDocuments <= data.length
    }, [pagination, data]);
    const [ratingLevel, setRatingLevel] = React.useState<TRatingLevel[] | undefined>([]);

    const [isLoadingNewPage, setIsLoadingNewPage] = React.useState<boolean>(true);
    React.useLayoutEffect(() => {
        setIsLoadingNewPage(false);
    }, [data])
    const scrollData = useScroll();
    const isLoading = useSelector((state: RootState) => state.appInfo.isShowLoading)
    const onChangePaging: PaginationProps['onChange'] = page => {
        onChange({ page });
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const onLoadMore = React.useCallback(async () => {
        setIsLoadingNewPage(true);
        onChange({
            page: 1,
            limit: data.length + 12
        })
    }, [onChange, data]);

    const loadRatingLevel = React.useCallback(async () => {
        try {
            const res = await getTestRatingLevel();
            if (res.data?.length > 0) {
                setRatingLevel(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }, []);

    React.useEffect(() => {
        loadRatingLevel();
    }, []);

    React.useEffect(() => {
        if (pagingMode === "infinite" && !isLoadingNewPage && scrollData && !isReachEnd && !isMobile) {
            const {
                innerHeight,
                scrollHeight,
                scrollYPosition
            } = scrollData
            // Hardcode that data was loaded
            if (scrollHeight > innerHeight * 1.5 && scrollYPosition + innerHeight + PX_NEED_TO_LOAD_NEXT_PAGE >= scrollHeight) {
                onLoadMore()
            }
        }
    }, [
        scrollData,
        pagingMode,
        onLoadMore,
    ]);

    const hasEmptyFilter = () => {
        if (!filterParams) return true;
        const notExpectProperties = ['page', 'limit'];
        const keyParams = Object.keys(filterParams).filter(x => !notExpectProperties.includes(x));
        let isEmptyValue = true;
        Object.entries(filterParams).forEach(([key, value]) => {
            if (keyParams.includes(key)) {
                if (Array.isArray(value) && value.length > 0) {
                    isEmptyValue = false;
                } else if (!Array.isArray(value) && value !== null && value !== '') {
                    isEmptyValue = false;
                }
            }

        });
        return isEmptyValue;
    };

    return (
        <div className="lg-container mx-auto flex justify-between w-full">
            <div className="grid grid-cols-1 gap-[16px] mb-[30px] w-full">
                {data?.length === 0 && !isLoading && <LibraryNoData onSearch={onReloadData} />}

                {data?.length > 0 && !hasEmptyFilter() && showResult && <div className="grid grid-cols-[auto_auto] justify-start items-center gap-[24px] w-full">
                    <span style={{
                        fontSize: '16px',
                        lineHeight: '19px',
                        fontWeight: 500,
                        color: '#5E6072'
                    }}>Có <span style={{ color: '#7357FF' }}>{pagination?.totalDocuments}</span> kết quả tìm kiếm</span>
                    <HRButton
                        btnType="tertiary"
                        btnSize="sm"
                        onClick={onReloadData}>
                        <div className="flex gap-[8px] items-center">
                            <ICUndo stroke="#4F4B5C" />
                            Tìm mới
                        </div>
                    </HRButton>
                </div>}
                <div
                    className="grid grid-cols-1 gap-[16px] lg:grid-cols-4"
                >
                    {
                        data.map(item => {
                            return <div
                                key={item._id}
                                className="m-2 lg:m-0"
                            >
                                {
                                    itemRender ? itemRender(item, keyword)
                                        : <LibraryCard data={item} keyword={keyword} ratingLevel={ratingLevel} />
                                }

                            </div>
                        })
                    }
                </div>
                {data?.length > 0 && pagingMode === "pagination" && <Row gutter={16} className="grid items-center justify-center w-full mt-8 lg:mt-[104px] ">
                    <HRPagination
                        onChange={onChangePaging}
                        currentPage={pagination?.page}
                        pageSize={pagination?.limit}
                        total={pagination?.totalDocuments}
                        size={isMobile ? "small" : "default"}
                    />
                </Row>}
                {
                    pagingMode === "infinite" && isMobile && <div className="flex justify-center">
                        <Button
                            onClick={onLoadMore}
                            loading={isLoadingNewPage}
                        >
                            Load more
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}
