import TextArea from "antd/lib/input/TextArea";
import { Iframe } from "../../../components/iframe";
import { TEssayChoiceQuestion, TGroupQuestion } from "../../create_test/tab/question/type";
import React from "react";
import _ from "lodash";
import { AudioPlayer } from "./AudioPlayer";

type GroupQuestionRenderProps = {
    question: TGroupQuestion;
    onChangeCorrectAnswer: (answer: number[] | string[]) => void;
    correctAnswer?: number[] | string[]
    currentQuestionIndex: number;
}
export const GroupQuestionRender: React.FC<GroupQuestionRenderProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
    currentQuestionIndex,
}) => {
    const onAnswerChange = (answer: string, index: number) => {
        onChangeCorrectAnswer(question.questions.map((question, i) => i === index ? answer : correctAnswer?.[i] as string));
    }
    return <div className="flex flex-col py-[32px] px-2 lg:px-[48px] rounded-[12px] bg-white shadow-e-03 box-border overflow-hidden gap-[56px]">
        <div className="w-full">
            <div className="pl-[24px] flex flex-col gap-[24px] border-l-[3px] border-[#C6BBFF]">
                <div className="text-standard-medium text-primary-bold">CÂU {currentQuestionIndex}</div>
                <Iframe content={question?.content as string || ''} />
                {
                    question.audioUrl && <AudioPlayer
                        questionId={question._id}
                        audioUrl={question.audioUrl}
                        maximumPlayAudio={question.maximumPlayAudio}
                    />
                }
            </div>
        </div>
        <div className="flex flex-col gap-[16px] w-full">
            <div className="text-standard-medium text-low-em">PHẦN TRẢ LỜI</div>
            {
                question.questions.map((question, index) => {
                    return <SubQuestionRenderer
                        index={index}
                        question={question}
                        answer={correctAnswer?.[index] as string}
                        onAnswerChange={(answer) => {
                            onAnswerChange(answer, index);
                        }}
                    />
                })
            }
        </div>
    </div>
}

type SubQuestionRendererProps = {
    index: number;
    question: TEssayChoiceQuestion;
    answer?: string;
    onAnswerChange: (answer: string) => void;
}
const SubQuestionRenderer: React.FC<SubQuestionRendererProps> = ({
    index,
    question,
    answer,
    onAnswerChange
}) => {
    const [content, setContent] = React.useState<string>(answer ?? '');
    const debouncedChange = React.useCallback(_.debounce(onAnswerChange, 100, {
        trailing: true,
    }), [onAnswerChange]);
    const onChangeContent = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setContent(e.target.value);
        debouncedChange(e.target.value);
    }
    React.useEffect(() => {
        setContent(answer ?? '');
    }, [answer]);
    return <div>
        <div className="lg:flex lg:space-x-2 items-center">
            <span className="text-sub font-semibold">Câu hỏi {index + 1}: </span>
            <span className="font-[14px] font-semibold">{question.content}</span>
        </div>
        <TextArea
            value={content}
            onChange={onChangeContent}
        />
    </div>
}