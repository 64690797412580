import { CloudOutlined, HighlightOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Drawer, Menu } from "antd";
import { ICBrand, ICCredit, ICLogout, ICScreen, ICUserV2 } from "../icons";
import { useNavigate } from "react-router-dom";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { removeUserInfo } from "../redux/actions";
import { setLoading } from "../redux/slices/appInfo";
import { logout } from "../api/auth";

type MobileMenuDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
}


export const MobileMenuDrawer: React.FC<MobileMenuDrawerProps> = ({
    isOpen,
    onClose
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onRedirect = (path: string) => {
        onClose();
        navigate(path);
    }
    const onLogoutUser = useCallback(async () => {
        dispatch(setLoading(true));
        try {
            await logout();
        } catch (e) {
            console.error(e)
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch]);
    const onLogout = useCallback(() => {
        onLogoutUser();
        dispatch(removeUserInfo());
        navigate("/login");
    }, [dispatch, navigate, onLogoutUser]);
    return <Drawer
        title="Menu"
        onClose={onClose}
        open={isOpen}
        bodyStyle={{ padding: 0 }}
    >
        <div>
            <Menu
                defaultOpenKeys={['account']}
                mode="inline"
                items={[
                    {
                        key: 'account',
                        label: 'Tài khoản',
                        icon: <UserOutlined />,
                        children: [
                            {
                                key: 'my-profile',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/profile")}>
                                    <ICUserV2 width={20} height={20} />
                                    <span>Hồ sơ của tôi</span>
                                </div>
                            },
                            {
                                key: 'my-account',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/account")}>
                                    <ICCredit
                                        width={20} height={20}
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>Tài khoản</span>
                                </div>
                            },
                            {
                                key: 'my-brand',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/company")}>
                                    <ICBrand
                                        width={20} height={20}
                                        fill="#8D8A95"
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>Thiết lập thương hiệu</span>
                                </div>
                            },
                            {
                                key: 'my-devices',
                                label: <div className="flex space-x-2 items-center" onClick={() => onRedirect("/devices")}>
                                    <ICScreen
                                        width={20} height={20}
                                    /*fill={isActive ? "#7357FF" : "#8D8A95"} */
                                    />
                                    <span>Thiết bị</span>
                                </div>
                            },
                        ],
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'assessment',
                        label: <div onClick={() => onRedirect("/my-assessment")}>Bài đánh giá</div>,
                        icon: <HighlightOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'candidate',
                        label: <div onClick={() => onRedirect("/my-candidate")}>Ứng viên</div>,
                        icon: <UserOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'library',
                        label: <div onClick={() => onRedirect("/library")}>Thư viện đề</div>,
                        icon: <CloudOutlined />
                    },
                    {
                        type: 'divider',
                    },
                    {
                        key: 'logout',
                        label: <div className="text-standard-medium text-[#F03D3D]" onClick={onLogout}>Đăng xuất</div>,
                        icon: <LogoutOutlined />
                    }
                ]}
            />
        </div>
    </Drawer>
}