import { Tabs, Tooltip } from "antd"
import { useCreateTestPageContext } from ".."
import { ICLock, ICAlertCircle, ICTick } from "../../../icons";
import { HomeTab } from "./home"
import { QuestionTab } from "./question/index";
import { OverviewTab } from "./overview"
import { HRTooltip } from "../../../components/tooltip";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { HRButton } from "../../../components/button";

type TableHeaderProps = {
    title: string,
    icon?: React.ReactNode
}
const TableHeader: React.FC<TableHeaderProps> = ({
    title,
    icon
}) => {
    return <>
        {icon && <HRTooltip
            placement="bottom"
            content={`Nhấn “Lưu” sau khi điền thông tin Trang chủ trước khi đến phần ${title}`}
            hasInfoIcon={true}
            childNode={(
                <div className="flex items-center justify-center">
                    <div className="flex space-x-2 items-center justify-center text-standard-bold text-high-em">
                        {icon}
                        <span>{title}</span>
                    </div>
                </div>
            )}
        />}

        {!icon && <div className="flex items-center justify-center">
            <div className="flex space-x-2 items-center justify-center text-standard-bold text-high-em">
                {icon}
                <span>{title}</span>
            </div>
        </div>}
    </>
}
const TABS_CONFIG = [{
    label: `Tổng quan`,
    children: <OverviewTab />,
    icon: <ICLock />
}, {
    label: `Trang Chủ`,
    children: <HomeTab />,
}, {
    label: `Câu hỏi`,
    children: <QuestionTab />,
    icon: <ICLock />
}];

export const CreateTestTabs: React.FC = () => {
    const { mode, selectedTabIndex, setSelectedTabIndex } = useCreateTestPageContext();
    return (
        <Tabs
            activeKey={`${selectedTabIndex + 1}`}
            onChange={(key) => setSelectedTabIndex(Number(key) - 1)}
            className="full-width-tabs"
            type="card"
            items={TABS_CONFIG.map((tab, index) => ({
                key: `${index + 1}`,
                label: <TableHeader title={tab.label} icon={mode === 'create' ? tab.icon : null} />,
                children: tab.children,
                disabled: mode === 'create' && index !== 1
            }))}
            renderTabBar={(props, DefaultTabBar) => {
                return (
                    <DefaultTabBar
                        {...props}
                        style={{
                            width: '100%',
                            padding: '0 0px',
                            borderRadius: '10px 10px 0 0',
                            margin: 0,
                            height: '56px'
                        }}
                    />
                )
            }}
        />
    )
}

type MobileTableHeaderProps = {
    onSave: () => void
}
export const MobileCreateTestTabs: React.FC<MobileTableHeaderProps> = ({ onSave }) => {
    const { mode, selectedTabIndex, setSelectedTabIndex } = useCreateTestPageContext();
    return (
        <div className="block text-center font-bold">
            <hr />
            <div className="flex pt-4 items-center justify-between pb-2">
                <span
                    className={
                        selectedTabIndex > 0 && mode !== "create" ? "cursor-pointer" : "cursor-not-allowed"
                    }
                    onClick={() => {
                        if (selectedTabIndex > 0 && mode !== "create") {
                            setSelectedTabIndex(selectedTabIndex - 1)
                        }
                    }}
                >
                    <LeftCircleOutlined style={{ fontSize: "20px" }} />
                </span>
                <div className="pt-1">
                    {
                        TABS_CONFIG[selectedTabIndex].label
                    }
                </div>
                <span
                    className={
                        (selectedTabIndex < TABS_CONFIG.length - 1) && mode !== "create" ? "cursor-pointer" : "cursor-not-allowed"
                    }
                    onClick={() => {
                        if (selectedTabIndex < TABS_CONFIG.length - 1 && mode !== "create") {
                            setSelectedTabIndex(selectedTabIndex + 1)
                        }
                    }}
                >
                    <RightCircleOutlined style={{ fontSize: "20px" }} />
                </span>
            </div>
            <hr />
            <div className="pt-2"></div>
            {
                TABS_CONFIG[selectedTabIndex].children
            }
            <div className="pt-2">
                <HRButton btnSize="md" onClick={onSave}>
                    <div className="flex space-x-2 justify-center">
                        <ICTick />
                        <span>Lưu</span>
                    </div>
                </HRButton>
            </div>
        </div>
    )
}
