import React from "react";
import { formatLocaleDateTimeString } from "../../../utils/date";
import { TCandidateTestingData } from "../../testing/type";
import { ICClapperboard } from "../../../icons";
import { Modal } from "antd";
import { HRButton } from "../../../components/button";
import { ApiInstance } from "../../../api";
import rrwebPlayer from 'rrweb-player';
import 'rrweb-player/dist/style.css';
import { useMobile } from "../../../hooks/useDimensions";

type ScreenRecordProps = {
    sessionId: string;
    startAt: number;
    candidateDetails: TCandidateTestingData;
    files: string[]
}
export const ScreenRecord: React.FC<ScreenRecordProps> = ({
    sessionId,
    startAt,
    files,
    candidateDetails
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const dateFormatted = React.useMemo(() => {
        return formatLocaleDateTimeString(startAt);
    }, [startAt]);
    const onCloseModal = React.useCallback(() => {
        setIsOpen(false);
    }, []);
    return <>
        <div
            className="cursor-pointer flex flex-col items-center gap-[8px] rounded-[4px] border border-[#E5E5E5] p-[16px] hover:border-[#1890FF]"
            onClick={() => {
                setIsOpen(true);
            }}
        >
            <ICClapperboard />
            <div
                className="text-sm text-center"
            >Sesion {dateFormatted}</div>
        </div>
        {isOpen && <ScreenRecordModal
            testCandidateId={candidateDetails._id!}
            sessionId={sessionId}
            files={files}
            onClose={onCloseModal}
            isOpen={isOpen}
            dateFormatted={dateFormatted}
        />}
    </>
}

type ScreenRecordModalProps = {
    testCandidateId: string;
    sessionId: string;
    files: string[];
    onClose: () => void;
    isOpen: boolean;
    dateFormatted: string;
}
const getSessionData = (testCandidateId: string, sessionId: string, fileId: string) => {
    return ApiInstance.getInstance().get(`/api/test-candidate/session-record/${testCandidateId}/${sessionId}/${fileId}`).then(res => {
        return res.data;
    }).catch(err => {
        console.warn('getSessionData', err);
        return [];
    });
}
const ScreenRecordModal = React.memo<ScreenRecordModalProps>(({
    onClose,
    isOpen,
    dateFormatted,
    testCandidateId,
    sessionId,
    files
}) => {
    const isMobile = useMobile();
    const rrwebPlayerRef = React.useRef<any>();
    const currentFileIdx = React.useRef<number>(0);
    const isClosedRef = React.useRef(false);
    React.useEffect(() => {
        if (!isOpen || files.length === 0) return;
        // Wait for modal to open
        setTimeout(() => {
            loadData();
        }, 100);
        return () => {
            // Remove rrweb player
            if (rrwebPlayerRef.current) {
                rrwebPlayerRef.current = null;
            }
        }
    }, [isOpen]);
    const loadData = React.useCallback(() => {
        const fileName = files[currentFileIdx.current];
        if (!fileName) return;
        getSessionData(testCandidateId, sessionId, fileName).then(res => {
            if (!rrwebPlayerRef.current) {
                if (isClosedRef.current) return;
                const target = document.getElementById('session-container');
                rrwebPlayerRef.current = new rrwebPlayer({
                    target: document.getElementById('session-container')!,
                    props: {
                        events: res,
                        autoPlay: true,
                        width: isMobile ? 340 : 945,
                        height: 440,
                    },
                });
            } else {
                res.forEach((event: any) => rrwebPlayerRef.current.addEvent(event))
            }

            currentFileIdx.current++;
            loadData();
        })
    }, [sessionId, testCandidateId, files, isMobile]);
    const onCloseModal = React.useCallback(() => {
        if (rrwebPlayerRef.current) {
            rrwebPlayerRef.current.$destroy();
            rrwebPlayerRef.current = null;
            isClosedRef.current = true;
        }
        onClose();
    }, [onClose]);
    return <Modal
        centered
        onCancel={onCloseModal}
        open={isOpen}
        width={isMobile ? 360 : 1000}
        title={
            <div className="flex justify-between items-center pr-[32px]">
                <div className="text-title-medium text-primary">Screen record at {dateFormatted}</div>
            </div>
        }
        footer={
            <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                <HRButton
                    btnType="sub"
                    btnSize="sm"
                    onClick={onCloseModal}>
                    Đóng
                </HRButton>
            </div>
        }>
        <div
            id="session-container"
            className="min-h-[500px]"
        />
    </Modal>
});