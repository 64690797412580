import { Typography } from 'antd';
import React from 'react';
import { useCandidateLayoutContext } from '../../../../layout/candidate';

export const AbilitiesCheckConfirmation: React.FC = () => {
    const { companyProfile } = useCandidateLayoutContext();

    return <div className="flex bg-white lg:max-w-[562px] rounded-[12px] px-4 lg:px-8 py-[54px] shadow-e-03">
        <div className="grid grid-cols-1 gap-[24px] items-start justify-start">
            <div className="text-left">
                <Typography.Title level={3} style={{
                    color: '#110C22',
                    fontWeight: 700,
                    marginBottom: 0,
                    fontSize: '28px',
                    lineHeight: '40px'
                }}>Thông tin của bạn đã được gửi đi</Typography.Title>

            </div>
            <div className="grid grid-cols-1 gap-[8px]">
                <span className="text-standard text-med-em">
                    Chúng tôi đã gửi thông tin của bạn đến {`${companyProfile?.name || 'HUSA'}`}. Đừng quên thường xuyên kiểm tra hòm thư đến để không bỏ lỡ email phản hồi mới nhất.
                </span>
            </div>
        </div>
    </div>
}
