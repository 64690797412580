import React from "react"
import { useCreateTestPageContext } from "../.."
import { IconButton } from "../../../../components/button"
import { ICBack } from "../../../../icons/back"
import { EQuestionType } from "./type"
import { QuestionCreator } from "./create-question"
import { ICMultiChoice, ICSingleChoice, ICEssay } from "../../../../icons"

type AddQuestionProps = {
}
export const AddQuestion: React.FC<AddQuestionProps> = ({
}) => {
    const [step, setStep] = React.useState<number>(0);
    const { setShowAddQuestion, selectedQuestion, skillAreaTypeSelected } = useCreateTestPageContext();
    const [questionType, setQuestionType] = React.useState<EQuestionType>(selectedQuestion?.type ?? EQuestionType.SINGLE_CHOICE);
    const onBack = React.useCallback(() => {
        setShowAddQuestion(false);
    }, []);
    const onChoose = React.useCallback((type: EQuestionType) => {
        setStep(1);
        setQuestionType(type);
    }, []);
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [step])
    return step === 0 && !selectedQuestion ? <SelectQuestionType
        onBack={onBack}
        onChoose={onChoose}
    /> : <QuestionCreator
        selectedQuestion={selectedQuestion}
        questionType={questionType}
        skillAreaTypeSelected={skillAreaTypeSelected}
        onCancel={onBack}
    />
}

type SelectQuestionTypeProps = {
    onBack: () => void
    onChoose: (type: EQuestionType) => void
}
const SelectQuestionType: React.FC<SelectQuestionTypeProps> = ({
    onBack,
    onChoose
}) => {
    return <div className="pb-16 space-y-8 text-left lg-container mx-auto">
        <div className="flex items-center justify-between">
            <div className="flex space-x-2 items-center">
                <IconButton btnType="sub" btnSize="sm" icon={<ICBack />} onClick={onBack} />
                <span className="text-heading-5-bold text-high-em">Lựa chọn loại câu hỏi</span>
            </div>
        </div>
        <div className="mt-8">
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-8 p-8 rounded-[12px] shadow-e-03 border box-border">
                <QuestionTypeItem
                    type={EQuestionType.SINGLE_CHOICE}
                    imgSrc={<ICSingleChoice height={70} width={70} />}
                    title="Câu hỏi 1 đáp án"
                    description="Trắc nghiệm 1 đáp án"
                    onChoose={onChoose}
                />
                <QuestionTypeItem
                    type={EQuestionType.MULTIPLE_CHOICE}
                    imgSrc={<ICMultiChoice height={70} width={70} />}
                    title="Câu hỏi nhiều đáp án"
                    description="Trắc nghiệm nhiều đáp án"
                    onChoose={onChoose}
                />
                <QuestionTypeItem
                    type={EQuestionType.ESSAY}
                    imgSrc={<ICEssay width={70} height={70} />}
                    title="Câu hỏi tự luận"
                    onChoose={onChoose}
                />
                <QuestionTypeItem
                    type={EQuestionType.GROUP}
                    imgSrc={<ICMultiChoice height={70} width={70} />}
                    title="Nhóm câu hỏi"
                    onChoose={onChoose}
                />
                <QuestionTypeItem
                    type={EQuestionType.SPEAKING}
                    imgSrc={<ICMultiChoice height={70} width={70} />}
                    title="Câu hỏi phát âm"
                    onChoose={onChoose}
                />
            </div>
        </div>
    </div>
}
type QuestionTypeItemProps = {
    type: EQuestionType
    imgSrc: React.ReactNode;
    title: string
    description?: string
    onChoose: (type: EQuestionType) => void
}
const QuestionTypeItem: React.FC<QuestionTypeItemProps> = ({
    type,
    imgSrc,
    title,
    description,
    onChoose
}) => {
    return <div
        className="flex flex-col gap-[24px] p-[16px] items-center cursor-pointer border border hover:border-purple rounded-[4px]"
        onClick={() => onChoose(type)}
    >
        {imgSrc}
        <div className="flex flex-col gap-[8px] items-center">
            <div className="text-standard-bold">{title}</div>
            <div className="text-body-medium">{description}</div>
        </div>
    </div>
}
