import { ApiInstance } from "..";
import { convert2QueryString } from "../../utils/convert2QueryString";

import { ISearchCriteria } from "../../pages/library/models";

export const getTestLibrary = (payload: ISearchCriteria) => {
  const requestURL = "/api/test?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
};

export const saveTestLibrary = (payload: any) => {
  return ApiInstance.getInstance().post("/api/test", payload);
};

export const updateTestLibrary = (id: string, payload: any) => {
  return ApiInstance.getInstance().put(`/api/test/${id}`, payload);
};

export const getMyTestLibrary = (payload: ISearchCriteria) => {
  const requestURL = "/api/test/my-test?" + convert2QueryString(payload);
  return ApiInstance.getInstance().get(requestURL);
};

export const getTestLibraryById = (id: string) => {
  return ApiInstance.getInstance().get(`/api/test/${id}`);
};

export const previewTestById = (id: string) => {
  return ApiInstance.getInstance().get(`/api/test/${id}/preview`);
};

export const deleteTestLibrary = (id: string) => {
  return ApiInstance.getInstance().delete(`/api/test/${id}`);
};

export const shareTestLibrary = (id: string, email: string) => {
  return ApiInstance.getInstance().post(`/api/test/give-away`, {
    id,
    email
  });
};

export const getTestRatingLevel = () => {
  return ApiInstance.getInstance().get(`/api/test/rating-level`);
};
