import React from "react";
import { AssessmentQuestions } from "./components/questions";
import { ICEssay, ICMultiChoice, ICSingleChoice } from "../../../../../icons"
import { HRButton } from "../../../../../components/button";
import { SectionAssessment } from "./components/section";
import { LibraryQuestion } from "./components/library-question";
import { useAssementData } from "../../hooks/useCreateAssessment"
import { TAdditionalQuestion } from "../../type";
import { FormInstance } from "antd/lib/form";
import useForm from "antd/lib/form/hooks/useForm";
import * as Scroll from 'react-scroll';
import { MAX_ADDITIONAL_QUESTION } from "../..";
import { getCustomQuestion } from "../../../../../api/assessment";
import debouce from 'lodash/debounce';
import classNames from 'classnames';
import styled from "styled-components";
import { EQuestionType, indexQuestion } from "../../../../create_test/tab/question/type";

const Actions = styled.div`
    &.disabled-add-button {
        background-color: #E2E4EB;
        color: #B2B4BF;
        cursor: not-allowed;
    }
`;


type TQuestionType = {
    type: EQuestionType;
};

export type TFormFilter = {
    title?: string;
    content?: string;
    duration?: number;
    isActive?: boolean;
    id?: string;
    page?: number;
    limit?: number;
    questionType?: TQuestionType["type"];
}

type TAddQuestionTypes = {
    type: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY;
    icon: React.ReactNode;
    title: string;
}

type TAssementAdditionalTest = {
    additionalQuestions: TAdditionalQuestion[];
    setAdditionalQuestions: React.Dispatch<React.SetStateAction<TAdditionalQuestion[]>>;

    // filter
    formFilter: TFormFilter
    setFormFilter: React.Dispatch<React.SetStateAction<TFormFilter>>,

    filterQuestionForm: FormInstance<any>;
    libraryQuestions: TAdditionalQuestion[];
    setLibraryQuestions: React.Dispatch<React.SetStateAction<TAdditionalQuestion[]>>;

    notAllowAddQuestion: boolean;
}
// TODO: Hiện tại additional Question chỉ support choiceable question
export const AssessmentAdditionalTestContext = React.createContext<TAssementAdditionalTest>({} as TAssementAdditionalTest);
export const useAssessmentAdditionalTest = () => React.useContext(AssessmentAdditionalTestContext);
const addQuestionTypes: TAddQuestionTypes[] = [
    {
        type: EQuestionType.SINGLE_CHOICE,
        icon: <ICSingleChoice />,
        title: 'Single Choice',
    },
    {
        type: EQuestionType.MULTIPLE_CHOICE,
        icon: <ICMultiChoice />,
        title: 'Multiple Choice',
    },
    {
        type: EQuestionType.ESSAY,
        icon: <ICEssay width={49} height={49} />,
        title: 'Tự luận',
    }
];

export const AssessmentAdditionalTest: React.FC = () => {
    const { additionalQuestions, setShowAddQuestion, setAdditionalQuestions } = useAssementData();
    const [filterQuestionForm] = useForm();
    const [libraryQuestions, setLibraryQuestions] = React.useState<TAdditionalQuestion[]>([]);
    const [formFilter, setFormFilter] = React.useState<TFormFilter>({});
    const notAllowAddQuestion = React.useMemo(() => {
        return additionalQuestions.length >= MAX_ADDITIONAL_QUESTION;
    }, [additionalQuestions]);

    const onAddQuestion = React.useCallback((type: EQuestionType.SINGLE_CHOICE | EQuestionType.MULTIPLE_CHOICE | EQuestionType.ESSAY) => {
        if (additionalQuestions.length >= MAX_ADDITIONAL_QUESTION) {
            return;
        }
        setShowAddQuestion(true, type);
    }, [setShowAddQuestion, additionalQuestions]);

    const loadLibraryQuestions = async (values: any) => {
        const newParams = { ...values };
        const results = await getCustomQuestion(newParams);
        const { data } = results || {};
        const newData = data.data || [];
        const MAX_LOAD_QUESTION = 50;
        setLibraryQuestions(newData.slice(0, MAX_LOAD_QUESTION).map((item: any) => indexQuestion(item)));
    };

    React.useEffect(() => {
        debouce(loadLibraryQuestions, 600)(formFilter);
    }, [formFilter]);

    return (
        <AssessmentAdditionalTestContext.Provider
            value={{
                additionalQuestions,
                setAdditionalQuestions,

                formFilter,
                setFormFilter,

                filterQuestionForm,
                libraryQuestions,
                setLibraryQuestions,

                notAllowAddQuestion
            }}
        >
            <div className="space-y-12">
                <div className="lg:p-8 pt-0 grid grid-cols-1 gap-[48px]">
                    <SectionAssessment
                        title="Danh sách câu hỏi bổ sung"
                        subTitle={`Bạn có thể thêm tối đa ${MAX_ADDITIONAL_QUESTION} câu hỏi thêm vào bài đánh giá`}
                        content={
                            <div className="max-w-full overflow-x-auto grid grid-cols-1 mt-[24px] gap-[12px] rounded-[12px] py-[16px] bg-white">
                                <div className="min-w-[780px]">
                                    <div
                                        className="grid grid-cols-[auto_auto_1fr] gap-[50px] pl-[95px] text-high-em text-body-bold"
                                    >
                                        <span>Loại</span>
                                        <span>Thời lượng</span>
                                        <span>Câu hỏi</span>
                                    </div>
                                    <AssessmentQuestions
                                        data={additionalQuestions}
                                        onChange={setAdditionalQuestions}
                                    />
                                </div>
                            </div>} />

                    <SectionAssessment
                        title="Tạo câu hỏi bổ sung"
                        subTitle="Giúp bạn khám phá ra những khía cạnh và tố chất của ứng viên."
                        classess="flex"
                        content={<div className="flex flex-col w-full lg:w-max">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[32px]">
                                {
                                    addQuestionTypes.map((item, index) => {
                                        return <Actions key={index}
                                            onClick={() => onAddQuestion(item.type)}
                                            className={
                                                classNames(
                                                    "grid grid-cols-1 gap-[10px] w-full lg:w-[226px] cursor-pointer h-[109px] rounded-[8px] p-[14px] bg-white justify-items-center items-center",
                                                    additionalQuestions.length >= MAX_ADDITIONAL_QUESTION ? 'disabled-add-button' : ''
                                                )}
                                        >
                                            {item.icon}
                                            <span className="text-high-em text-body-medium">{item.title}</span>
                                        </Actions>
                                    })
                                }
                            </div>
                            <div className="grid mt-[24px]">
                                <Scroll.Link to="library-questions" spy={true} smooth={true} offset={50} duration={500}>
                                    <HRButton btnType='sub' btnSize="md">
                                        ....hoặc sử dụng từ thư viện câu hỏi
                                    </HRButton>
                                </Scroll.Link>
                            </div>
                        </div>} />

                    <SectionAssessment
                        title="Thư viện câu hỏi"
                        subTitle="Bạn có thể sử dụng các câu hỏi phổ biến dưới đây để"
                        content={<LibraryQuestion />} />
                </div>
            </div>
        </AssessmentAdditionalTestContext.Provider>
    );
};

