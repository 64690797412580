import React from "react"
import { HRForm } from "../../components/form/form";
import { HRInput } from "../../components/form/input";
import { HRTextArea } from "../../components/form/textarea";
import { EMAIL_REGEX } from "../../utils/constants";
import { Form } from "antd";
import { useCompanyPageContext } from ".";
import { HRSelect } from "../../components/form/select";
import { ICGlobal } from "../../icons";
import { HRTooltip } from "../../components/tooltip";

export const CompanyProfile: React.FC = () => {
    const { companyProfileForm, listNationality } = useCompanyPageContext();

    const childForm = (
        <div className="grid gap-4 lg:gap-[24px] grid-cols-[1fr]">
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: "Xin hãy nhập Tên công ty",
                    },
                ]}
            >
                <HRInput label="Tên công ty" isRequired={true} maxLength={100} />
            </Form.Item>
            <HRTooltip 
                content="Sử dụng cho mục đích xuất hóa đơn điện tử"
                childNode={
                    <Form.Item
                        name="taxCode"
                    >
                        <HRInput label="Mã số thuế" maxLength={14} />
                    </Form.Item>
                }
            />
            <Form.Item
                name="address"
                rules={[
                    {
                        required: true,
                        message: "Xin hãy nhập Địa chỉ công ty",
                    },
                ]}
            >
                <HRTextArea
                    label="Địa chỉ công ty"
                    maxLength={500}
                    isRequired={true}
                    style={{
                        minHeight: "98px",
                    }}
                />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[
                    {
                        pattern: EMAIL_REGEX,
                        message: 'Email không đúng định dạng',
                    },
                ]}
            >
                <HRInput label="Email" maxLength={100} />
            </Form.Item>
            <Form.Item
                name="country"
                rules={[
                    {
                        required: true,
                        message: "Xin hãy nhập Quốc gia",
                    },
                ]}
            >
                <HRSelect
                    allowClear={false}
                    label="Quốc gia"
                    isRequired={true}
                    placeholder="Chọn Quốc gia"
                    icon={<ICGlobal fill="#C6C5CA" />}
                    style={{
                        width: "100%",
                    }}
                    options={listNationality.map(nat => ({ label: nat.enShortName, value: nat._id }))}
                />
            </Form.Item>
        </div>
    );

    return <div className="flex flex-col w-full gap-[24px] p-3 lg:p-8 bg-white rounded-[12px] shadow-e-03 box-border overflow-hidden">
        <div className="flex text-heading-5-medium text-primary-bold">
            Thông tin công ty
        </div>
        <HRForm form={companyProfileForm} name="createCompanyProfile" childNode={childForm} />
    </div>
}
