import { Modal, Row, Table } from "antd"
import { ICDownload, ICInfoV2, ICMoMo, ICNoneData, ICVNPay } from "../../icons/index";
import styled from "styled-components";
import { HRButton, IconButton } from "../../components/button";
import React from "react";
import { HRPagination } from "../../components/pagination";
import { IPagination } from "../library/models";
import { getMyOrder } from "../../api/order";
import { TOrderDetails } from "../payment_status/type";
import { formatLocaleDateString } from "../../utils/date";
import { formatMoney } from "../../utils";
import { EPaymentMethod, PaymentMethosMapping } from "../payment/type";
import { downloadInvoice } from "../../api/payment";
import { setLoading } from "../../redux/slices/appInfo";
import { useDispatch } from "react-redux";
import { useNotification } from "../../hooks/useNotification";

const Wrapper = styled.div`
    .ant-table {
        border-radius: 12px;
        box-shadow: 0px 6px 16px -6px rgba(17, 12, 34, 0.10);
    }
    .ant-table-content {
        font-size: 14px;
        color: #110C22;
        line-height: 24px; 
    }
    .ant-table-thead {
        background-color: #F8F8F8;
    }
    .ant-table-wrapper.white-header thead[class*="ant-table-thead"] th {
        background-color: #F8F8F8 !important;
        font-weight: 700;
        font-size: 14px;
        color: #110C22;
        line-height: 24px;
    }
    .ant-table-thead>tr>th {
        font-weight: 700;
    }
    .ant-table-content>table>.ant-table-tbody>tr,
    .ant-table-tbody>tr>td {
        border: 0 !important;
    }
`;

type TSortData = {
    field?: string;
    order?: 'asc' | 'desc';
}

type TSortDataContext = {
    sortData: TSortData;
    setSortData: (data: TSortData) => void;
}
const SortDataContext = React.createContext<TSortDataContext>({} as TSortDataContext);
export const useSortData = () => React.useContext(SortDataContext);

export const Transaction: React.FC = () => {
    const dispatch = useDispatch();
    const { showError, showSuccess } = useNotification();
    const [transactions, setTransactions] = React.useState<TOrderDetails[]>([]);
    const [sortData, setSortData] = React.useState<TSortData>({ field: 'createdAt', order: 'desc' });
    const [pagination, setPagination] = React.useState<IPagination>({
        limit: 10,
        page: 1,
        totalDocuments: 0,
        totalPages: 0,
    });
    const query = React.useMemo(() => {
        const formatSortData = `${sortData.field}::${sortData.order}`;
        return {
            orderBy: formatSortData,
            page: pagination.page,
            limit: pagination.limit,
        }
    }, [pagination.page, sortData, pagination.limit]);
    const [showInvoiceDetails, setShowInvoiceDetails] = React.useState(false);
    const [transactionDetails, setTransactionDetails] = React.useState<TOrderDetails | null>(null); // [TODO
    const [selectedInvoiceUrl, setSelectedInvoiceUrl] = React.useState<string | null>(null);

    const PaymentMethodList = [
        {
            id: 1,
            code: EPaymentMethod.VNPAY,
            logo: <ICVNPay width={24} height={24} />,
        },
        {
            id: 2,
            code: EPaymentMethod.MOMO,
            logo: <ICMoMo width={24} height={24} />,
        },
    ];

    const onDownloadFile = (orderId: string, value: string) => {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${value}`;
        link.download = `${orderId}.pdf`;
        link.click();
    }

    const loadInvoiceData = React.useCallback(async (orderId: string) => {
        dispatch(setLoading(true));
        try {
            const result = await downloadInvoice(orderId);
            const newValues = decodeURIComponent(result.data?.fileToBytes);
            setSelectedInvoiceUrl(newValues);
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("Error", errorMessage);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError]);

    const onDownloadInvoice = React.useCallback(async (orderId: string) => {
        dispatch(setLoading(true));
        try {
            const result = await downloadInvoice(orderId);
            const newValues = decodeURIComponent(result.data?.fileToBytes);
            onDownloadFile(orderId, newValues);
            showSuccess('Đã tải thanh toán thành công');
        }
        catch (error: any) {
            const errorMessage = error?.response?.data?.errorMessage || error?.response?.statusText;
            errorMessage && showError("Error", errorMessage);
        }
        finally {
            dispatch(setLoading(false));
        }
    }, [dispatch, showError, showSuccess]);

    const onShowInvoiceDetails = React.useCallback((invoice: TOrderDetails) => {
        selectedInvoiceUrl && setSelectedInvoiceUrl(null);
        setTransactionDetails(invoice);
        loadInvoiceData(invoice._id);
        setShowInvoiceDetails(true);
    }, [loadInvoiceData, selectedInvoiceUrl]);

    const columns = [
        {
            title: 'Hóa đơn',
            dataIndex: ['generalInvoiceInfo', 'difinition'],
            key: '_id',
            render: (text: string, row: any) => {
                const orderCode = row.generalInvoiceInfo?.invoiceNo;
                const type = row.difinition.type;
                const typeName = type === "assessment" ? "Assessment" : type === "candidate_credit" ? "Candidate Credit" : "Unknown"
                return <span className="text-body text-high-em">{typeName} #{orderCode}</span>
            }
        },
        {
            title: 'Ngày',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: any) => {
                return <div className="text-body text-high-em">
                    {formatLocaleDateString(createdAt as number, true)}
                </div>;
            }
        },
        {
            title: 'Thành tiền',
            dataIndex: 'pricing',
            key: 'pricing',
            render: (pricing: any) => {
                return <div className="text-primary text-body-bold">
                    {formatMoney(pricing?.totalPrice as number)}
                </div>;
            }
        },
        {
            title: 'Phương thức thanh toán',
            dataIndex: 'paymentMethod',
            render: (paymentMethod: EPaymentMethod) => {
                const paymentSelection = PaymentMethodList.find((item) => item.code === paymentMethod);
                return <div className="flex space-x-2 text-body text-high-em items-center">
                    <span>{paymentSelection?.logo}</span>
                    <span>{PaymentMethosMapping[paymentSelection?.code as EPaymentMethod]}</span>
            </div>;
            }
        },
        {
            title: 'Hành động',
            dataIndex: '_id',
            key: '_id',
            render: (value: string, record: any) => {
                if (!record?.generalInvoiceInfo) {
                    return null;
                }
                return <div className="flex gap-[16px]">
                    <span onClick={() => onDownloadInvoice(value)}>
                        <IconButton btnSize="sm" btnType="sub_info" icon={<ICDownload fill="#0084FF" />} hoverText="Tải hóa đơn"/>
                    </span>
                    <span onClick={() => onShowInvoiceDetails(record)}>
                        <IconButton btnSize="sm" btnType="sub_info" icon={<ICInfoV2 fill="#0084FF" />} hoverText="Chi tiết hóa đơn"/>
                    </span>
                </div>
            },
        },
    ];

    const onChangePaging = React.useCallback((page: number, pageSize?: number) => {
        setPagination((prev) => ({
            ...prev,
            page,
        }));
    }, []);

    const loadMyOrders = React.useCallback(() => {
        getMyOrder(query).then((res) => {
            setTransactions(res.data.data);
            setPagination(res.data.metaData);
        })
    }, [query]);

    const onDownloadInvoicePDF = React.useCallback(() => {
        if (selectedInvoiceUrl && transactionDetails) {
            onDownloadFile(transactionDetails?._id, selectedInvoiceUrl);
        };
    }, [transactionDetails, selectedInvoiceUrl]);

    React.useEffect(() => {
        loadMyOrders();
    }, [query]);
    
    return (
        <Wrapper className="flex flex-col gap-[24px]">
            <div className="flex flex-col gap-[5px]">
                <div className="text-heading-5-bold text-high-em">Lịch sử thanh toán</div>
                <div className="text-body">Tải về hóa đơn để xem chi tiết.</div>
            </div>
            <SortDataContext.Provider value={{
                sortData,
                setSortData,
            }}>
                <Table
                    className="white-header max-w-full overflow-x-auto"
                    columns={columns}
                    dataSource={transactions}
                    rowClassName="cursor-pointer data-row"
                    rowKey="_id"
                    locale={{
                        emptyText: <div className="my-6 flex flex-col gap-[32px]">
                            <div className="flex justify-center">
                                <ICNoneData />
                            </div>
                            <div className="text-standard-bold text-high-em">
                                Không có thanh toán nào
                            </div>
                        </div>
                    }}
                    pagination={false}
                />
            </SortDataContext.Provider>
            {pagination && pagination.totalPages > 1 && <Row gutter={16} className="mt-3 grid items-center justify-end w-full">
                <HRPagination
                    onChange={onChangePaging}
                    defaultCurrent={pagination?.page}
                    defaultPageSize={pagination?.limit}
                    total={pagination?.totalDocuments}
                    currentPage={pagination?.page}
                    showSizeChanger={false}
                />
            </Row>}

        <Modal
            centered
            onCancel={() => setShowInvoiceDetails(false)}
            open={showInvoiceDetails}
            width={1000}
            title={
                <div className="flex justify-between items-center pr-[32px]">
                    <div className="text-title-medium text-primary">Chi tiết hóa đơn</div>
                    <div>
                        <HRButton btnSize="sm" onClick={onDownloadInvoicePDF}>
                            <div className="flex gap-[8px] text-body-medium text-white">
                                <ICDownload fill="white" />
                                <span>Tải xuống</span>
                            </div>
                        </HRButton>
                    </div>
                </div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowInvoiceDetails(false)}>
                        Hủy
                    </HRButton>
                </div>
            }>
            {selectedInvoiceUrl && <div>
                <embed width="100%" height="500px" src={`data:application/pdf;base64,${selectedInvoiceUrl}`}  type="application/pdf"></embed>
            </div>}
        </Modal>
        </Wrapper>
    )
}
