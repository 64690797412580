import React from "react"
import styled from "styled-components"
import { EStatusAssessment, ISearchMyAssessment } from "./type";
import classNames from 'classnames';

const Status = styled.div`
    &.active {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 27px;
            height: 4px;
            border-radius: 0px 0px 4px 4px;
            background-color: #7357FF;
        }
    }
`;

type TFilterStatusProps = {
    status: EStatusAssessment | undefined;
    setStatus: (value: EStatusAssessment) => void;
    onChangeFilter: (value: any) => void;
    filterParams: ISearchMyAssessment;
}
const STATUS = [
    {
        label: "Tất cả",
        value: undefined,
    },
    {
        label: "Công khai",
        value: "active",
    },
    {
        label: "Nháp",
        value: "draft",
    },
    {
        label: "Lưu trữ",
        value: "archived",
    },
    {
        label: "Khóa",
        value: "locked",
    }
];
export const FilterStatus: React.FC<TFilterStatusProps> = ({
    status,
    setStatus,
    onChangeFilter,
    filterParams
}) => {
    const onToggle = React.useCallback((value: any) => {
        setStatus(value);
        onChangeFilter({ status: value, page: 1, orderBy: undefined });
    }, [setStatus, onChangeFilter]);

    const renderStatus = React.useMemo(() => {
        return STATUS.map((item, index) => {
            return <Status
                key={index} 
                onClick={() => onToggle(item.value)}
                className={classNames(
                    "relative cursor-pointer p-[8px_24px] text-body-medium",
                    "flex items-center justify-center",
                    {
                        "text-primary active": item.value === status
                    },
                    {
                        "text-low-em": item.value !== status
                    }
                )}>
                {item.label}
            </Status>

        })
    }, [status, onToggle]);

    return <div
        className="flex-wrap w-full flex items-center justify-start bg-white border-b border-solid border-outline-med rounded-t-[12px]"
    >
        {renderStatus}
    </div>
}
