import React from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAssementData } from "../../../hooks/useCreateAssessment";
import { ILibrary } from "../../../../../library/models";
import classNames from 'classnames'
import { ICViewWhite, ICClose } from "../../../../../../icons";
import { HRTooltip } from "../../../../../../components/tooltip";
import { HRButton } from "../../../../../../components/button";
import { Modal } from "antd";
import { reorder } from "../../../../../../utils";

const getListStyle = (isDraggingOver: boolean) => ({
    background: 'white', // isDraggingOver ? "white" : "white",
    width: '100%'
});
const getMoveItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    background: isDragging ? "white" : "white",
    color: isDragging ? "white" : "inherit",
    borderRadius: isDragging ? "8px" : "inherit",
    border: isDragging ? "1px solid #7357FF" : "inherit",
    boxShadow: isDragging ? "0px 4px 16px rgba(79, 117, 140, 0.24)" : "inherit",
    ...draggableStyle
});

type TSelectTests = {
    isSticky?: boolean;
};

export const SelectTests: React.FC<TSelectTests> = ({ isSticky }) => {
    const { tests, setTests } = useAssementData();
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const [seletedData, setSeletedData] = React.useState<ILibrary>();

    // Drop && Drag
    const onDragEnd = (result: any) => {
        const { source, destination } = result;
        if (!result.destination) {
            return;
        }
        const newTests = reorder(
            tests,
            source.index,
            destination.index
        );
        tests.splice(0, tests.length, ...newTests);
    };

    const renderContent = (data: ILibrary, index: number) => {
        return <div>
            {data?._id && <div className="grid grid-cols-[1fr_24px_24px] gap-[2px] p-[16px]">
                <span className="flex">
                    <HRTooltip content={data.title}
                        placement="bottom"
                        childNode={<span className="max-w-[100px] line-clamp-1 text-body-bold text-primary">{data.title}</span>}>
                    </HRTooltip>
                </span>
                {data?.previewSection && data?.previewSection?.length === 0 && <span></span>}
                {data?.previewSection && data?.previewSection?.length > 0 && <span onClick={
                    () => {
                        window.open(`/testing/${data._id}/preview`, '_blank');
                    }
                }><ICViewWhite fill="#7357FF" /></span>}

                <span onClick={
                    () => {
                        setSeletedData(data);
                        setShowDeleteModal(true);
                    }
                }>
                    <ICClose fill="#7357FF" width={24} height={24} />
                </span>
            </div>}
            {!data?._id && <span className="text-standard-medium text-low-em p-[16px]">Đề kiểm tra {index + 1}</span>}
        </div>
    };

    const onDelete = React.useCallback(() => {
        const index = tests.findIndex((item) => {
            if (item?.uuid === undefined && seletedData?.uuid === undefined) {
                return item?._id === seletedData?._id;
            }
            return item?.uuid === seletedData?.uuid;
        });
        if (index !== -1) {
            setTests((prev) => {
                const newTests = [...prev];
                newTests[index] = {
                    ...newTests[index],
                    _id: undefined
                };
                const newTestsOrder = reorder(newTests, index, newTests.length - 1);
                return newTestsOrder;
            });
        }
        // const newTests = reorder(tests, index, tests.length - 1);
        // tests.splice(0, tests.length, ...newTests);
        // setTests(newTests);
        setShowDeleteModal(false);
    }, [seletedData, tests, setTests]);

    return <div className="flex flex-col justify-center items-center w-full">
        <div className={`grid grid-cols-1 bg-white ${isSticky ? 'lg-container' : 'w-full'}`}>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                        <div className="lg-container grid grid-cols-2 lg:grid-cols-5 gap-[16px] bg-white"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {tests.map((item, index) => (
                                item._id ?
                                <Draggable
                                    key={`key_${item._id || item.uuid || index}`}
                                    draggableId={`id_${item._id || item.uuid || index}`}
                                    index={index}
                                    isDragDisabled={!item._id}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getMoveItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <div
                                                className={classNames(
                                                    "w-full border-[1px] border-outline-primary-med text-center rounded-[8px]",
                                                    item._id ? "text-white border-none bg-surface-primary-accent-2" : "bg-white text-n-400 border-dashed"
                                                )}
                                                key={index}
                                                id={`test_num_${index}`}
                                            >
                                                {
                                                    renderContent(item, index)
                                                }
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                                : (
                                    <div
                                        className={classNames(
                                            "w-full border-[1px] border-s-light-purple text-center py-[18.5px] text-base font-bold rounded-[8px]",
                                            item._id ? "bg-surface-primary-accent-2 text-white border-none" : "bg-white text-n-400 border-dashed"
                                        )}
                                        key={index}
                                        id={`test_num_${index}`}
                                    >
                                        {
                                            renderContent(item, index)
                                        }
                                    </div>
                                )
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">Gỡ khỏi danh sách</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        Trở về
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onDelete();
                            setShowDeleteModal(false)
                        }}>
                        Gỡ
                    </HRButton>
                </div>
            }
        >
            <p>Bạn có muốn gỡ <strong>{seletedData?.title}</strong> khỏi danh sách của bạn?</p>
        </Modal>
    </div>
}
