import { Col, Row } from "antd";
import React from "react";
import { usePaymentPageContext } from ".";
import { HRPrice } from "../../components/price";
import { formatLocaleDateString } from "../../utils/date";

export const ListPayment: React.FC = () => {
    const { paymentDetails } = usePaymentPageContext();
    const renderItem = React.useMemo(() => {
        if (paymentDetails?.paidTests?.length === 0) return [];
        return paymentDetails?.paidTests?.map((item: any) => {
            return {
                ...item,
                title: item.title,
                type: item.type,
                price: item.price,
            }
        })
    }, [paymentDetails?.paidTests]);

    return <div className="flex flex-col w-full gap-[16px]">
        <span className="text-high-em leading-[28px] text-[18px] font-bold">Thông tin đơn hàng</span>
        <div className="flex flex-col w-full">
            <div className="lg:flex w-full gap-[32px] bg-white p-[16px] rounded-t-[8px] shadow-header-payment">
                <div className="flex flex-col gap-[5px]">
                    <span className="text-[#676472] text-[10px] leading-[16px] font-bold">BÀI ĐÁNH GIÁ</span>
                    <span className="text-[#7357FF] text-[14px] leading-[24px] font-bold">{paymentDetails?.assessment?.title}</span>
                </div>
                <div className="flex bg-[#ECECED] h-wull w-[1px]"></div>
                <div className="flex gap-[32px]">
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-[#676472] text-[10px] leading-[16px] font-bold">ĐỀ ĐIỂM TRA</span>
                        <span className="text-high-em text-[14px] leading-[24px] font-bold">{paymentDetails?.paidTests?.length}</span>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-[#676472] text-[10px] leading-[16px] font-bold">NGÀY</span>
                        <span className="text-high-em text-[14px] leading-[24px] font-bold">{formatLocaleDateString(paymentDetails?.assessment?.activeDate as number, true)}</span>
                    </div>
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-[#676472] text-[10px] leading-[16px] font-bold">HẠN SỬ DỤNG</span>
                        <span className="text-high-em text-[14px] leading-[24px] font-bold">{formatLocaleDateString(paymentDetails?.assessment?.expireDate as number, true)}</span>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <Row
                    style={{
                        borderTop: '1px solid #ECECED',
                        padding: '0px 24px',
                    }}
                    gutter={16}
                >
                    <Col span={10}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-[#B3B1B8] text-[12px] leading-[16px] font-bold">
                                TÊN
                            </span>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="flex flex-col p-[16px] pl-0">
                            <span className="text-[#B3B1B8] text-[12px] leading-[16px] font-bold">
                                LOẠI ĐỀ KIỂM TRA
                            </span>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className="flex flex-col p-[16px] pl-0 items-end">
                            <span className="text-[#B3B1B8] text-[12px] leading-[16px] font-bold">
                                ĐƠN GIÁ
                            </span>
                        </div>
                    </Col>
                </Row>
                {
                    renderItem?.map((item: any, index: number) => {
                        return <Row
                            key={index}
                            style={{
                                padding: '0px 24px',
                                borderBottom: '1px solid #ECECED',
                                borderTop: '1px solid #ECECED',
                            }}
                            gutter={16}
                        >
                            <Col span={10}>
                                <div className="flex flex-col p-[16px] pl-0">
                                    <span className="text-high-em text-[14px] leading-[24px] font-medium">
                                        {item.title}
                                    </span>
                                </div>
                            </Col>
                            <Col span={6}>
                                <div className="flex flex-col p-[16px] pl-0">
                                    <span className="text-high-em text-[14px] leading-[24px] font-medium">
                                        {item.isPublic && item.planTag === 'pay' ? 'Premium Test' : 'My Company Test'}
                                    </span>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className="flex flex-col p-[16px] pl-0 items-end">
                                    <span className="text-high-em text-[14px] leading-[24px] font-medium">
                                        <HRPrice data={item.price} custommUI={true} />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    })
                }
            </div>

        </div>
    </div>;
}
