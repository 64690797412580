import React from "react";
import { HRButton } from "../../../components/button";
import { ICHeadPhone, ICNext } from "../../../icons";
import { ICBack } from "../../../icons/back";
import { indexQuestion, TQuestion } from "../../create_test/tab/question/type";
import { QuestionRender } from "./question";
import { usePreviewTestingAssessmentPageContext } from "..";
import { ICCheckV2 } from '../../../icons/check';
import { CameraViewer } from "../../testing/test/components/camera_viewer";
import { QuestionList } from "../../testing/test/components/question_list";
import { TQuestionTesting } from "../../testing/type";

export const PreviewTestDetail: React.FC = () => {
    const { testDetails } = usePreviewTestingAssessmentPageContext();
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(0);
    const [answers, setAnswers] = React.useState<(number[] | number | undefined)[]>([]);

    const questions = React.useMemo(() => {
        return testDetails.tests?.flatMap(test => test.previewSection).map((question) => {
            return indexQuestion(question);
        }) as TQuestionTesting[] || [];
    }, [testDetails]);

    const onChangeCorrectAnswer = React.useCallback(async (answer: number[] | number | undefined) => {
        setAnswers(prev => {
            const newAnswers = [...prev];
            newAnswers[currentQuestionIndex] = answer;
            return newAnswers;
        });
        
    }, [currentQuestionIndex]);

    const onNavigateQuestion = React.useCallback((index: number) => {
        setCurrentQuestionIndex(index);
    }, []);

    const onFinishTest = React.useCallback(() => {
        window.opener = null;
        window.open("", "_self");
        window.close();
    }, []);

    return <div className="lg-container mx-auto">
        <div className="flex justify-end">
            <div className="flex space-x-4">
                <HRButton
                    btnType="secondary"
                    onClick={() => {
                        onNavigateQuestion(currentQuestionIndex - 1);
                    }}
                    disabled={currentQuestionIndex === 0}
                >
                    <div className="text-base font-bold flex space-x-2 items-center justify-center text-n-800">
                        <ICBack />
                        <span>Câu trước</span>
                    </div>
                </HRButton>
                {
                    currentQuestionIndex === questions.length - 1 ?
                        <HRButton
                            btnType="primary"
                            onClick={onFinishTest}>
                            <div className="text-base font-bold flex space-x-2 items-center justify-center">
                                <ICCheckV2 />
                                <span>Hoàn tất</span>
                            </div>
                        </HRButton> :
                        <HRButton
                            btnType="primary"
                            onClick={() => {
                                onNavigateQuestion(currentQuestionIndex + 1);
                            }}
                        >
                            <div className="text-base font-bold flex space-x-2 items-center justify-center">
                                <span>Tiếp tục</span>
                                <ICNext />
                            </div>
                        </HRButton>
                }
            </div>
        </div>
        <div className="mt-[40px]">
            <QuestionRender
                question={questions[currentQuestionIndex] as TQuestion}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={answers[currentQuestionIndex]}
            />
        </div>
        <div className="grid grid-cols-[1fr_1fr_106px] gap-[80px] justify-start items-center mt-[40px]">
            <CameraViewer />
            <QuestionList
                currentQuestionIndex={currentQuestionIndex}
                onNavigateQuestion={onNavigateQuestion}
                correctAnswer={answers}
                sectionName={testDetails?.title}
                questionData={questions} />
            <HRButton
                shape="circle"
                btnSize="sm"
                btnType="sub"
                style={{
                    borderRadius: '144px',
                }}
            >
                <div className="flex gap-[8px] items-center justify-center">
                    <ICHeadPhone />
                    <span className=" text-body-medium text-icon-high-em">Hỗ trợ</span>
                </div>
            </HRButton>
        </div>
    </div>
}
