import { Row, Table } from "antd"
import { ColumnType, ColumnsType } from "antd/lib/table";
import React from "react";
import classNames from "classnames";
import Highlighter from "react-highlight-words";
import { HRPagination } from "../../components/pagination";
import { ICSort, ICForward, ICNoneData } from "../../icons";
import { IPagination } from "../library/models";
import { TMyCandidateData } from "./type";
import { SortOrder } from "antd/lib/table/interface";
import { useSortData } from ".";
import { formatLocaleDateString } from "../../utils/date";
import { useNavigation } from "../../hooks/useNavigate";
import styled from "styled-components";
import { useMobile } from "../../hooks/useDimensions";

const WrapperTable = styled.div`
    .ant-table-thead>tr>th {
        border: 0;
    }
    .ant-table-tbody>tr.ant-table-row>td {
        border-top: 1px solid transparent !important;
        border-bottom: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:first-child {
        border-left: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row >td:last-child {
        border-right: 1px solid transparent !important;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:first-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 12px;
    }
    .ant-table-tbody>tr.ant-table-row:hover>td:last-child, .ant-table-tbody>tr>td.ant-table-cell-row-hover:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 12px;
    }
`;

const TitleRenderer: React.FC<{ title: string }> = ({ title }) => {
    const { keyword } = React.useContext(TableContext);

    return <Highlighter
        highlightClassName="text-highlight"
        searchWords={[`${keyword || ''}`]}
        autoEscape={true}
        className="text-body text-high-em"
        textToHighlight={title || ''}
    />
}
type SortableColumnProps = {
    title: string;
    order?: SortOrder;
    name: string;
}
const SortableColumn: React.FC<SortableColumnProps> = ({
    title,
    order,
    name
}) => {
    const { setSortData } = useSortData();
    return <div className="flex space-x-3 items-center select-none group" onClick={() => {
        setSortData({
            field: order === 'descend' ? 'createdAt' : name,
            order: order === 'descend' ? 'desc' : !order ? 'asc' : 'desc',
        });
    }}>
        <span className="text-body-bold text-high-em">{title as string}</span>
        <ICSort order={order} />
    </div>
}
const columns: ColumnsType<TMyCandidateData> = [
    {
        title: 'Tên',
        dataIndex: 'fullName',
        key: 'fullName',
        sorter: true,
        render: (fullName: string) => {
            return <div className="min-w-[150px]">
                <TitleRenderer title={fullName} />
            </div>
        },
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '210px',
        sorter: true,
        render: (email: string) => {
            return <TitleRenderer title={email} />
        },
    },
    {
        title: 'Bài đánh giá',
        dataIndex: 'testCandidatesCount',
        key: 'testCandidatesCount',
        sorter: true,
        width: '170px',
        render: (testCandidatesCount: number) => {
            return <div className="pr-2 text-body text-high-em w-[170px]">
                <span>{testCandidatesCount ?? 0}</span>
            </div>
        }
    },
    {
        title: 'Hoạt động gần đây',
        dataIndex: 'lastActiveAt',
        key: 'lastActiveAt',
        sorter: true,
        width: '220px',
        render: (lastActiveAt: number) => {
            return <div className="pr-2 text-body text-high-em w-[220px]">
                <span>{formatLocaleDateString(lastActiveAt)}</span>
            </div>
        }
    },
    {
        title: '',
        dataIndex: '_id',
        key: '_id',
        width: '40px',
        render: () => {
            return <ICForward />;
        }
    }
].map((col: ColumnType<TMyCandidateData>) => {
    if (col.sorter) {
        const oldTitle = col.title;
        col.title = ({ sortColumns }) => {
            const sortedColumn = sortColumns?.find(({ column }) => column.key === col.key);
            return <SortableColumn
                title={oldTitle as string}
                order={sortedColumn?.order}
                name={col.dataIndex as string}
            />
        }
    } else {
        col.title = <div className="font-bold text-base">{col.title as string}</div>;
    }
    col.showSorterTooltip = false;
    return col
})

type TestListProps = {
    testList: TMyCandidateData[];
    keyword?: string;
    pagination?: IPagination;
    onChange: ({ page }: { page: number }) => void;
}
type TableContext = {
    keyword: string;
}
const TableContext = React.createContext<TableContext>({
    keyword: '',
} as TableContext);

export const TestList: React.FC<TestListProps> = ({
    testList,
    keyword,
    pagination,
    onChange,
}) => {
    const { navigate } = useNavigation();
    const onChangePaging = (page: number) => {
        onChange({ page })
    };
    const isMobile = useMobile();
    return <WrapperTable className="grid grid-cols-1 shadow-e-03 text-g-400 overflow-hidden box-border rounded-[12px]">
        <TableContext.Provider value={{
            keyword: keyword ?? '',
        }}>
            <Table
                className="white-header overflow-x-auto"
                columns={columns}
                dataSource={testList}
                rowKey="_id"
                rowClassName="cursor-pointer data-row"
                locale={{
                    emptyText: <EmptyTable />
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            navigate(`/candidate/${record?._id}/details`);
                        }
                    }
                }}
                pagination={false}
            />
        </TableContext.Provider>
        {pagination && pagination.totalPages > 1 && <Row gutter={16}
            style={{
                margin: 0,
            }}
            className="lg:grid items-center justify-center lg:justify-end w-full bg-white p-[16px] border-t border-solid border-outline-med rounded-b-[12px]">
            <HRPagination
                onChange={onChangePaging}
                defaultCurrent={pagination?.page}
                defaultPageSize={pagination?.limit}
                total={pagination?.totalDocuments}
                currentPage={pagination?.page}
                size={isMobile ? "small" : "default"}
            />
        </Row>}
    </WrapperTable>
}

const EmptyTable = () => {
    return (
        <div className="my-6 flex flex-col gap-[32px]">
            <div className="flex justify-center">
                <ICNoneData />
            </div>
            <div className="text-standard-bold text-high-em">Không có ứng viên nào</div>
        </div>
    )
}
