import { useCallback } from 'react';
import { ICAdd } from "../../icons/index";
import { useNavigate } from "react-router-dom";
import { HRButton } from "../../components/button"
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import React from 'react';
import { UserRoles } from '../../redux/slices/userInfo';

export const LibraryHeader: React.FC = () => {
    const navigate = useNavigate();
    const goMyTest = useCallback(() => {
        navigate('/my-test');
    }, [navigate]);
    const goCreateAssessment = useCallback(() => {
        navigate('/create-assessment');
    }, [navigate]);
    const userInfo = useSelector((state: RootState) => state.userInfo);
    const isSuponsor = React.useMemo(() => {
        return userInfo?.role === UserRoles.SPONSOR;
    }, [userInfo]);

    const goCreateTest = useCallback(() => {
        navigate('/create-test');
    }, [navigate]);
    return (
        <div className="lg-container mx-auto lg:flex justify-between px-2 lg:px-0">
            <div className="flex items-center justify-center space-x-6">
                <span className='text-heading-5-semi-bold text-high-em'>Thư viện đề kiểm tra</span>
            </div>
            <div className='px-6 lg:px-0 pt-2 lg:pt-0'>
                <div className={`grid grid-cols-1 lg:grid-cols-${isSuponsor ? "3" : "2"} gap-[16px]`}>
                    {isSuponsor && <div>
                        <HRButton btnSize="md" btnType="sub" onClick={goCreateTest}>
                            Đóng góp đề kiểm tra
                        </HRButton>
                    </div>}
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={goMyTest}>
                        Thư viện đề của tôi
                    </HRButton>
                    <HRButton
                        btnType="primary"
                        btnSize="md"
                        onClick={goCreateAssessment}>
                            <div className="flex gap-[8px] justify-center">
                                <ICAdd />
                                <span>
                                    Tạo bài đánh giá
                                </span>
                            </div>
                    </HRButton>     
                </div>               
            </div>
        </div>
    )
}
