import React from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../create-assessment/components/add-additional-test/components/editor.style.css";
import { HRButton } from "../../../components/button";
import { ICSendV2, ICEdit, ICArroba, ICSave, ICWarning, ICUpload } from "../../../icons";
import { HRInput } from '../../../components/form/input';
import { ICInviteEmail } from '../../../icons/inviteEmail';
import { HRForm } from '../../../components/form/form';
import { useForm, useWatch } from "antd/lib/form/Form";
import Form from "antd/lib/form";
import { List, Modal, Typography } from "antd";
import { ICBack } from "../../../icons/back";
import styled from "styled-components";
import { ICClose } from '../../../icons/close';
import classNames from 'classnames'
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { toolbar, isEmptyHTML } from "../create-assessment/components/add-additional-test/components/editor.config";
import draftToHtml from 'draftjs-to-html';
import { ICAvatar } from '../../../icons/avatar';
import { useAssessment } from "../create-assessment/hooks/useAssessment";
import { TCandidate, TCandidateRequest, TInvitationCandidate } from "../type";
import { EMAIL_REGEX } from "../../../utils/constants";
import { useAssementDetailsData } from ".";
import { useGeneralPageData } from "../../general";
import { EmailTemplateModal } from "./email-template/email-template";
import { TEmailTemplate } from "../create-assessment/type";
import { InviteTableModal } from "./InviteTableModal";

const StyledList = styled(List)`
    h4 {
        margin-bottom: 0;
    }
    .ant-col {
        padding: 8px;
        cursor: pointer;
        &:hover {
            background: #EEF0F6;
            border-radius: 12px;
            .remove-action {
                display: block;
            }
        }
        .remove-action {
            display: none;
        }
        .ant-list-item {
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
`;

type TInviteEmailAssessmentProps = {
    assessmentId: string;
    onReloadCandiateList: () => void;
    candidateList: TCandidate[];
    setCandidateList: React.Dispatch<React.SetStateAction<TCandidate[]>>;
}

export const InviteEmailAssessment: React.FC<TInviteEmailAssessmentProps> = ({
    assessmentId,
    onReloadCandiateList,
    candidateList = [],
    setCandidateList,
}) => {
    const [form] = useForm();
    const [openModal, setOpenModal] = React.useState<'candidate' | 'email' | null>();
    const { loadInvitation, inviteCandidate, updateEmailTemplate, inviteListCandidate } = useAssessment();
    const [isOpenModalInvite, setIsOpenModalInvite] = React.useState<boolean>(false);
    const emailFormValue = useWatch('email', form);
    const firstNameFormValue = useWatch('firstName', form);
    const [isValidContentEmail, setIsValidContentEmail] = React.useState<boolean>(true);
    const [showConfirmInvite, setShowConfirmInvite] = React.useState<boolean>(false);
    const { assessmentDetails, setAssessmentDetails, myCandidateCreditInfo } = useAssementDetailsData();

    const onUpdateEmailTemplate = React.useCallback((template: TEmailTemplate) => {
        updateEmailTemplate(assessmentId, template, setAssessmentDetails);
    }, [assessmentId, setAssessmentDetails, updateEmailTemplate]);

    React.useEffect(() => {
        if (assessmentId) {
            loadInvitation(assessmentId, { isFull: true }, setCandidateList);
        }
    }, []);

    const onSubmitInviteEmail = React.useCallback(() => {
        setShowConfirmInvite(false);
        form.validateFields().then((results: any) => {
            inviteCandidate(assessmentId, results, setCandidateList, setAssessmentDetails, onReloadCandiateList);
            form.resetFields();
        }).catch((err) => console.warn(err));
    }, [form, assessmentId, inviteCandidate, setCandidateList, setAssessmentDetails, onReloadCandiateList]);
    const onSubmitInviteList = React.useCallback((data: TCandidateRequest[]) => {
        inviteListCandidate(assessmentId, data);
    }, [assessmentId, inviteListCandidate]);
    const isFormValid = React.useMemo(() => {
        if (!emailFormValue || !firstNameFormValue) {
            return false;
        }
        if (emailFormValue) {
            const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
            return emailRegex.test(emailFormValue);
        }

        return true;
    }, [emailFormValue, firstNameFormValue]);

    const disableInviteUser = React.useMemo(() => {
        return !isFormValid || myCandidateCreditInfo?.remainingCandidateCredit === 0;
    }
        , [isFormValid, myCandidateCreditInfo?.remainingCandidateCredit]);

    const childForm = (
        <div className="grid gap-[16px] grid-cols-[1fr]">
            <Form.Item
                name="email"
                rules={[
                    {
                        pattern: EMAIL_REGEX,
                        message: 'Email không đúng định dạng',
                    },
                    {
                        required: true,
                        message: "Bạn chưa điền email",
                    },
                ]}
            >
                <HRInput label="Email" prefix={<ICArroba />} isRequired={true} maxLength={50} />
            </Form.Item>
            <Form.Item name="lastName">
                <HRInput label="Họ" maxLength={20} />
            </Form.Item>
            <Form.Item
                name="firstName"
                rules={[
                    {
                        required: true,
                        message: "Bạn chưa điền Tên",
                    },
                ]}
            >
                <HRInput label="Tên" isRequired={true} maxLength={20} />
            </Form.Item>

        </div>
    );

    return <div className="grid grid-cols-1 gap-[32px]">
        <div>
            <div className="grid grid-cols-1 gap-[32px]">
                <div className="grid grid-cols-[56px_1fr] gap-[16px] items-center">
                    <ICInviteEmail />
                    <span className="flex flex-col gap-[4px]">
                        <span className="text-high-em text-title-bold">Mời thông qua Email</span>
                        <span className="text-med-em text-body">
                            Trực tiếp mời ứng viên thông qua email
                        </span>
                    </span>
                </div>
                <div className="grid grid-cols-1">
                    <HRForm form={form} name="inviteAssessment" childNode={childForm} />
                </div>
            </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-[1fr_200px_100px] gap-[16px] items-center justify-end">
            <div
                onClick={() => setOpenModal('candidate')}
                className="cursor-pointer underline text-[#3D6AC4]">
                Danh sách đã mời
            </div>
            <EmailTemplateModal
                template={assessmentDetails?.emailTemplate}
                onUpdated={onUpdateEmailTemplate}
            />
            <HRButton
                onClick={() => {
                    if (assessmentDetails?.hasInvitedCandidate) {
                        onSubmitInviteEmail();
                        return;
                    }
                    setShowConfirmInvite(true);
                }}
                disabled={disableInviteUser}
                btnType="primary"
                btnSize="sm"
            >
                <div className="flex space-x-2 items-center justify-center">
                    <ICSendV2 width={18} height={18} fill={disableInviteUser ? "var(--icon-disabled)" : "white"} />
                    <span>Mời</span>
                </div>
            </HRButton>
        </div>
        <div>
            <div className="w-[280px]">
                <HRButton btnSize="sm" btnType="outline" onClick={() => setIsOpenModalInvite(true)}>
                    <div className="flex space-x-2 items-center text-med-em justify-center">
                        <ICUpload />
                        <span>Upload danh sách ứng viên</span>
                    </div>
                </HRButton>
            </div>

        </div>
        <Modal className="invite-email-modal"
            open={openModal === 'candidate' || openModal === 'email'}
            centered={true}
            footer={null}
            width={1140}
            onCancel={() => setOpenModal(null)}
            bodyStyle={{
                padding: '32px',
            }}
            onOk={() => setOpenModal(null)}
        >
            <div className="flex flex-col justify-start items-start gap-[24px]">
                <div className="flex items-center">
                    <Typography.Title level={4}
                        style={{
                            color: '#110C22',
                            fontSize: '24px',
                        }}
                        className="font-bold">
                        {openModal === 'candidate' ? 'Danh sách đã mời' : 'Chỉnh sửa mẫu email'}
                    </Typography.Title>
                </div>
                <div className="w-full">
                    {openModal === 'candidate' && <CandidateList candidateList={candidateList} setCandidateList={setCandidateList} onReloadCandiateList={onReloadCandiateList} assessmentId={assessmentId} />}
                    {openModal === 'email' && <TemplateEmail setIsValidContentEmail={setIsValidContentEmail} />}
                </div>
                <div className="flex items-center justify-end w-full gap-[16px]">
                    {openModal === 'candidate' && <span><HRButton
                        onClick={() => setOpenModal(null)}
                        btnType="primary"
                    >
                        <div className="text-[16px] font-bold flex items-center space-x-2 justify-center">
                            <ICBack height={24} width={24} fill="white" />
                            <span>Trở về</span>
                        </div>
                    </HRButton></span>}
                    {openModal === 'email' && <span><HRButton
                        onClick={() => setOpenModal(null)}
                        btnType="secondary"
                    >
                        <div className="text-[16px] text-[#46485C] font-bold flex items-center space-x-2 justify-center">
                            <ICClose fill="#46485C" width={24} height={24} />
                            <span>Hủy bỏ</span>
                        </div>
                    </HRButton></span>}
                    {openModal === 'email' && <span><HRButton
                        onClick={() => setOpenModal(null)}
                        disabled={!isValidContentEmail}
                        btnType="primary"
                    >
                        <div className="text-[16px] font-bold flex items-center space-x-2 justify-center">
                            <ICSave fill={!isValidContentEmail ? '#B2B4BF' : 'white'} />
                            <span>Lưu</span>
                        </div>
                    </HRButton></span>}
                </div>
            </div>
        </Modal>
        <Modal
            centered
            onCancel={() => setShowConfirmInvite(false)}
            open={showConfirmInvite}
            title={
                <div className="font-bold text-[18px]">Mời ứng viên tham gia bài đánh giá</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowConfirmInvite(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={onSubmitInviteEmail}>
                        Xác nhận
                    </HRButton>
                </div>
            }
        >
            <p>Bài đánh giá sẽ chuyển sang trạng thái "<strong>Không thể chỉnh sửa</strong>" một khi đã thực hiện mời ứng viên. Bạn có thể làm thử Bài đánh giá trước khi thực sự mời ứng viên.</p>
            <p>Bạn có chắc chắn muốn thực hiện hành động này.</p>
        </Modal>
        <InviteTableModal
            isOpen={isOpenModalInvite}
            onClose={() => setIsOpenModalInvite(false)}
            onInvite={onSubmitInviteList}
        />
    </div>
}

type TTemplateEmailProps = {
    setIsValidContentEmail: (isValid: boolean) => void;
}
const TemplateEmail: React.FC<TTemplateEmailProps> = ({
    setIsValidContentEmail
}) => {
    const [contentEmail, setContentEmail] = React.useState<string>('');

    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(contentEmail).contentBlocks))
    );
    const onEditorStateChange = (valueEditorState: EditorState) => {
        const text = valueEditorState.getCurrentContent().getPlainText()
        const oldContent = editorState.getCurrentContent().getPlainText()
        // const lines = text.split(/\n/)
        if (text.length > 3000) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContent).contentBlocks);
            setEditorState(EditorState.moveFocusToEnd(EditorState.push(editorState, oldState, 'insert-characters')));
        } else {
            setEditorState(valueEditorState);
            setContentEmail(draftToHtml(convertToRaw(valueEditorState.getCurrentContent())))
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(valueEditorState)
        //     setEditorState(undo)
        // }
    }

    React.useEffect(() => {
        setIsValidContentEmail(!isEmptyHTML(contentEmail));
    }, [contentEmail, setIsValidContentEmail])

    return <div className="grid grid-cols-2 gap-[24px]">
        <div className="relative grid grid-cols-1 gap-[8px]">
            <label className="text-[16px] font-bold leading-[17px]">
                <span>Nội dung email</span>
            </label>
            <Editor
                toolbar={toolbar}
                editorState={editorState}
                toolbarClassName={classNames("editor-toolbar")}
                wrapperClassName={classNames("questions-wrapper", "editor-wrapper")}
                editorClassName={classNames("questions-editor", "editor-editor", "editor-editor-email")}
                onEditorStateChange={onEditorStateChange}
                stripPastedStyles={true}
            />
            {
                isEmptyHTML(contentEmail) && <div className="absolute bottom-[-25px] left-0 flex items-center space-x-2">
                    <ICWarning fill="#F55858" width={18} height={18} />
                    <span className="text-body italic text-error-500">Nội dung email rỗng</span>
                </div>
            }
        </div>
        <div className="grid grid-cols-1 gap-[8px]">
            <label className="text-[16px] font-bold leading-[17px]">
                <span>Xem trước</span>
            </label>
            <div className="h-[489px] border-[1px] border-solid border-[#BEC0CA] rounded-[4px] p-[12px] overflow-auto">
                <span dangerouslySetInnerHTML={{
                    __html: contentEmail
                }}></span>
            </div>
        </div>
    </div>
}

type ICandidateListProps = {
    candidateList: TCandidate[];
    setCandidateList: (candidateList: TCandidate[]) => void;
    assessmentId: string;
    onReloadCandiateList: () => void;
}

const CandidateList: React.FC<ICandidateListProps> = ({
    candidateList,
    setCandidateList,
    assessmentId,
    onReloadCandiateList,
}) => {
    const { removeInvitationCandidate } = useAssessment();
    const { setCvs } = useAssementDetailsData();
    const [data, setData] = React.useState<TCandidate[]>(candidateList);
    React.useEffect(() => {
        setData(candidateList);
    }, [candidateList])
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [dataSelected, setDataSelected] = React.useState<TCandidate>({} as TCandidate);
    const onRemove = React.useCallback((item: any) => {
        setDataSelected(item);
        setShowDeleteModal(true);
    }, [setDataSelected, setShowDeleteModal]);

    const removeSuccess = React.useCallback(() => {
        onReloadCandiateList();
        setData(data.filter((item: TCandidate) => item._id !== dataSelected._id));
        setCandidateList(candidateList.filter((item: TCandidate) => item._id !== dataSelected._id));
        setCvs(prev => {
            const indexCVSelected = prev.findIndex((item) => item._id !== dataSelected._id);
            if (prev[indexCVSelected]?.hasInvited) {
                prev[indexCVSelected].hasInvited = false;
            }
            return prev;
        });
    }, [candidateList, data, dataSelected._id, onReloadCandiateList, setCandidateList, setCvs]);

    const onRemoveCandidate = React.useCallback(() => {
        const payload: TInvitationCandidate = {
            assessmentId,
            candidateId: dataSelected?._id,
        };
        removeInvitationCandidate(payload, removeSuccess);
        setShowDeleteModal(false);
    }, [assessmentId, dataSelected?._id, removeInvitationCandidate, removeSuccess]);

    return <>
        <StyledList
            grid={{
                gutter: 16,
                xs: 1,
                sm: 2,
                md: 3,
                lg: 3,
                xl: 3,
                xxl: 3,
            }}
            className="w-full"
            dataSource={data}
            renderItem={(item: any) => {
                const inactiveDeleteButton = item?.status === 'in_progress' && !item?.isResting;
                return <List.Item>
                    <List.Item.Meta
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        avatar={<ICAvatar />}
                        title={<span className="text-[#46485C] text-[14px] font-medium">
                            {item?.email}
                        </span>}
                        description={<span className="text-[#A6A8B4] text-[14px] font-medium">
                            {`${item?.firstName} ${item?.lastName}`}
                        </span>}
                    />
                    {!inactiveDeleteButton && <span className="remove-action" onClick={() => onRemove(item)} >
                        <ICClose width={24} height={24} fill="#46485C" />
                    </span>}
                </List.Item>
            }
            }
        />
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">Xóa lời mời</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="secondary"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={onRemoveCandidate}>
                        Xác nhận
                    </HRButton>
                </div>
            }
        >
            <p>Ứng viên này sẽ không thể truy cập bài đánh giá của bạn.</p>
        </Modal>
    </>
}
