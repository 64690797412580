import React from "react";
import { HRForm } from "../../../../../../components/form/form";
import Form from "antd/lib/form";
import { HRInput } from "../../../../../../components/form/input";
import { HRSelect } from "../../../../../../components/form/select";
import { ICAdd, ICClose, ICSearch, ICSmallClock } from "../../../../../../icons";
import { Checkbox } from "antd";
import { HRButton } from "../../../../../../components/button";
import { ICquestionMarkCircleOutline } from '../../../../../../icons/question-mark-circle-outline';
import { ICSmallUser } from '../../../../../../icons/small-user';
import { useAssessmentAdditionalTest } from "..";
import { StatusQuestion } from "./status";
import { TAdditionalQuestion } from "../../../type";
import _ from "lodash";
import { useAssementData } from '../../../hooks/useCreateAssessment';
import { ICNoResultV2 } from '../../../../../../icons/no_results';
import styled from "styled-components";
import { QUESTIONS_TIMING, QuestionTypeMapping } from "../../../../../../utils/constants";
import { EQuestionType } from "../../../../../create_test/tab/question/type";

const Actions = styled.div`
  .disabled-add-button {
    background-color: #E2E4EB !important;
    color: #B2B4BF !important;
    cursor: not-allowed;
  }
`;

const FilterLibraryQuestion: React.FC = () => {
  const { myAssessment } = useAssementData();
  const { filterQuestionForm, formFilter, setFormFilter } = useAssessmentAdditionalTest();
  const questionTypes = [
    {
      value: EQuestionType.SINGLE_CHOICE,
      label: "Single Choice",
    },
    {
      value: EQuestionType.MULTIPLE_CHOICE,
      label: "Multiple Choice",
    }
  ];
  const childForm = (
    <div className="grid gap-[24px] grid-cols-[1fr]">
      <Form.Item name="title">
        <HRInput placeholder="Tìm kiếm" value={formFilter.content} prefix={<ICSearch />} />
      </Form.Item>
      <Form.Item name="duration">
        <HRSelect
          label="Thời lượng câu hỏi"
          name="duration"
          placeholder="Lựa chọn"
          value={formFilter.duration}
          options={QUESTIONS_TIMING}
        />
      </Form.Item>
      <Form.Item name="id">
        <HRSelect
          options={myAssessment.map((item) => {
            return {
              value: item._id,
              label: item.title,
            };
          })}
          label="Tên của bài đánh giá"
          name="title"
          value={formFilter.title}
          placeholder="Lựa chọn"
        />
      </Form.Item>
      <Form.Item name="type">
        <Checkbox.Group options={questionTypes} style={{
          fontSize: "14px",
          lineHeight: "17px",
          fontWeight: 400,
          color: "#5E6072",
          display: "grid",
          gap: "19px",
          justifyContent: "flex-start",
        }} />
      </Form.Item>
    </div>
  );

  const onValuesChanged = React.useCallback((changedValues: any, allValues: any) => {
    setFormFilter(allValues);
  }, [setFormFilter]);

  return (
    <>
      <HRForm
        form={filterQuestionForm}
        name="createAssessment"
        onValuesChange={onValuesChanged}
        childNode={childForm}
      />
    </>
  );
};

const ViewLibraryList: React.FC = () => {
  const { libraryQuestions, additionalQuestions, setAdditionalQuestions, notAllowAddQuestion } = useAssessmentAdditionalTest();
  const hasAdded = (value: TAdditionalQuestion) => {
    return additionalQuestions.some((item) => value && value._id === item._id);
  };

  const onActionQuestion = React.useCallback((value: TAdditionalQuestion) => {
    setAdditionalQuestions(prev => {
      const index = prev.findIndex((item) => item._id === value._id);
      if (index > -1) {
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      }else{
        return [...prev, value];
      }
    })
  }, [setAdditionalQuestions]);

  return (
    <div id="library-questions" className="grid grid-cols-1">
      {libraryQuestions?.length > 0
        ? <div className="grid grid-cols-1 gap-[16px]">
          {
            libraryQuestions.map((item) => {
              return (
                <LibraryItem 
                  key={item._id}
                  item={item}
                  isAdded={hasAdded(item)}
                  canAdded={!notAllowAddQuestion}
                  onToggleAdd={onActionQuestion}
                />
              )
            })
          }
        </div>
        : <div className="grid grid-cols-1 gap-[16px]">
          <div className="grid grid-cols-1 bg-white w-full rounded-[8px] items-center justify-center">
            <div className="grid grid-cols-1 items-center text-center gap-[32px]">
              <span className="flex text-center items-center justify-center w-full">
                <ICNoResultV2 />
              </span>
              <span className="text-[16px] font-bold leading-[19px]">Không có câu hỏi bổ sung nào</span>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

type LibraryItemProps = {
  item: TAdditionalQuestion;
  isAdded: boolean;
  canAdded: boolean;
  onToggleAdd: (item: TAdditionalQuestion) => void;
}
const LibraryItem = React.memo<LibraryItemProps>(({
  item,
  isAdded,
  canAdded,
  onToggleAdd
}) => {
  const [isExpand, setIsExpand] = React.useState<boolean>(false);
  return <div
    className="grid grid-cols-1 gap-[16px] bg-white rounded-[8px] p-[16px]">
    <div className="flex items-center justify-between">
      <span className="text-high-em text-body-bold">
        <span dangerouslySetInnerHTML={{ __html: item.title || '' }} />
      </span>
      <span className="grid grid-cols-2 gap-[16px]">
        {item.statusTag && <StatusQuestion text={item.statusTag} />}
        {item.planTag && <StatusQuestion text={item.planTag} />}
      </span>
    </div>

    <div className="flex items-center justify-start gap-[16px] pt-[4px] text-med-em text-sub-medium">
      <div className="flex gap-[5px] justify-items-center items-center">
        <span><ICSmallClock /></span>
        <span>{item.duration} phút</span>
      </div>
      <div className="flex gap-[5px] justify-items-center items-center">
        <span>
          <ICquestionMarkCircleOutline />
        </span>
        <span>
          {QuestionTypeMapping[item.type]}
        </span>
      </div>
      <div className="flex gap-[5px] justify-items-center items-center">
        <span><ICSmallUser /></span>
        <span>{item.where || 'Thư viện HR Product'}</span>
      </div>
    </div>

    <div className="h-[1.4px] w-full bg-[#E2E4EB]"></div>

    <div className="text-high-em text-body">
      <span dangerouslySetInnerHTML={{ __html: item.content || '' }}></span>
    </div>
    <Actions className="flex items-center justify-between gap-[16px]">
      <span>
        <HRButton btnType="tertiary_info" btnSize="sm"
          onClick={() => {
            setIsExpand(!isExpand);
          }}
        >{!item.isExpand ? 'Chi tiết' : 'Ẩn'}</HRButton>
      </span>
      <div><HRButton
        btnSize="md"
        onClick={() => onToggleAdd(item)}
        btnType={isAdded ? 'danger' : 'primary'}
        className={!canAdded && !isAdded ? 'disabled-add-button' : ''}>
        <div className="grid grid-cols-[auto_auto] space-x-2 items-center justify-center cursor-pointer">
          {
            isAdded ? <ICClose fill={"white"} width={24} height={24} />
              : <ICAdd />
          }
          <span>{isAdded ? "Xóa" : "Thêm"}</span>
        </div>
      </HRButton>
      </div>
    </Actions>
    {isExpand && <div className="w-full grid grid-cols-1 bg-[#E2E4EB] h-[1.4px]"></div>}
    {isExpand && <div className="grid grid-cols-2 gap-[16px] items-start">
      <div className="grid grid-cols-1 gap-[8px]">
        <span className="text-[#46485C] font-bold text-[14px] leading-[17px]">Why is this question relevant?</span>
        <span>
          {item.relatedReason}
        </span>
      </div>
      <div className="grid grid-cols-1 gap-[8px]">
        <span className="text-[#46485C] font-bold text-[14px] leading-[17px]">
          What to look for in the answer?
        </span>
        <span>
          {item.desiredAnswer}
        </span>
      </div>
    </div>}
  </div>
})

export const LibraryQuestion: React.FC = () => {
  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-[273px_1fr] gap-[16px]">
      <FilterLibraryQuestion />
      <ViewLibraryList />
    </div>
  );
};
