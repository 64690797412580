import { notification, Avatar } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { UploadAPI } from "../../api/upload";
import { updateUserInfo } from "../../api/user";
import { HRButton } from "../../components/button";
import { ICCamera } from "../../icons/index"
import { setLoading } from "../../redux/slices/appInfo";
import { setUserInfo, TUserInfo } from "../../redux/slices/userInfo";
import { getURL, goodFileSize, validateImageDimensions } from "../../utils";
import {
    WHITE_LIST_IMAGE_TYPES,
    BACKGROUND_MAX_HEIGHT,
    BACKGROUND_MAX_WIDTH,
    AVATAR_MAX_HEIGHT,
    AVATAR_MAX_WIDTH
} from "../../utils/constants";
import { AvatarModal } from "./avatar_modal";
import { UploadAvatar } from "./upload_avatar";
import { useNotification } from "../../hooks/useNotification";
import { resizeImage } from "./resize-img";

type HomeBackgroundProps = {
    user: TUserInfo,
    showSaveButton: boolean,
    onSave: () => void
    onCancel: () => void
}
export const HomeBackground: React.FC<HomeBackgroundProps> = ({
    user: userInfo,
    showSaveButton = false,
    onSave,
    onCancel
}) => {
    const { showSuccess } = useNotification();
    const inputAvatarRef = React.useRef<HTMLInputElement>(null);
    const inputBackgroudRef = React.useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const [file, setFile] = React.useState<File | undefined>();

    const onUploaded = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            // Validate file
            if (!WHITE_LIST_IMAGE_TYPES.includes(file.type)) {
                notification.error({
                    message: "Error",
                    description: "Định dạng file không hợp lệ"
                })
                return;
            }
            // if (!goodFileSize(file)) {
            //     notification.error({
            //         message: "Error",
            //         description: "File không được lớn hơn 10MB"
            //     })
            //     return;
            // }
            resizeImage(file, {
                maxWidth: AVATAR_MAX_WIDTH,
                maxHeight: AVATAR_MAX_HEIGHT,
            }).then((file) => {
                inputAvatarRef.current?.value && (inputAvatarRef.current.value = "");
                // @ts-ignore
                setFile(file);
            }).catch((e) => {
                notification.error({
                    message: "Error",
                    description: "Something went wrong"
                })
                console.error(e);
            })
        }
    }

    const callUpload = async (file: File) => {
        try {
            dispatch(setLoading(true))
            const { data } = await UploadAPI(file);
            const backgroudUrl = data.fileUrl;
            await updateUserInfo({ backgroundImage: backgroudUrl });
            dispatch(setUserInfo({ backgroundImage: backgroudUrl }));
            showSuccess('Thành công');
        } catch (e) {
            console.error(e)
            notification.error({
                message: "Something went wrong",
                placement: "bottom",
            });
        } finally {
            dispatch(setLoading(false))
        }
    };

    const onUploadedBackground = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            if (file) {
                // Validate file
                if (!WHITE_LIST_IMAGE_TYPES.includes(file.type)) {
                    notification.error({
                        message: "Error",
                        description: "Định dạng file không hợp lệ"
                    })
                    return;
                }
                // if (!goodFileSize(file)) {
                //     notification.error({
                //         message: "Error",
                //         description: "File không được lớn hơn 10MB"
                //     })
                //     return;
                // }
                resizeImage(file, {
                    maxWidth: BACKGROUND_MAX_WIDTH,
                    maxHeight: BACKGROUND_MAX_HEIGHT,
                }).then((file) => {
                    inputBackgroudRef.current?.value && (inputBackgroudRef.current.value = "");
                    // @ts-ignore
                    callUpload(file);
                }).catch((e) => {
                    notification.error({
                        message: "Error",
                        description: "Something went wrong"
                    })
                    console.error(e);
                })
            }
        }
    }
    const backgroudURL = React.useMemo(() => {
        if (userInfo.backgroundImage) return getURL(userInfo.backgroundImage);
        return "/images/bg_holder.jpg";
    }, [userInfo.backgroundImage])
    const avatar = React.useMemo(() => {
        return userInfo.avatar ? getURL(userInfo.avatar) : "/images/avatar_holder.jpg"
    }, [userInfo.avatar]);

    const [isOpenAvatarModal, setIsOpenAvatarModal] = React.useState(false);
    return (
        <div>
            <div className="relative h-[212px] lg:h-[342px]" style={{
                background: `url('${backgroudURL}') no-repeat center center/cover`
            }}>
                <div
                    onClick={() => inputBackgroudRef.current?.click()}
                    className="absolute top-4 right-[20px] lg:right-[120px] p-2 bg-n-800 cursor-pointer opacity-60 rounded-[4px]"
                >
                    <ICCamera fill="white" />
                </div>
                <input
                    onChange={onUploadedBackground}
                    accept="image/*"
                    type="file"
                    className="hidden"
                    ref={inputBackgroudRef}
                />
            </div>
            <div className="lg-container mx-auto flex space-x-2 lg:space-x-8">
                <div className="relative">
                    <div className="relative w-[120px] h-[120px] lg:w-[210px] lg:h-[210px]" style={{
                        marginTop: '-60px'
                    }}>
                        <Avatar src={avatar}
                            alt="avatar"
                            onClick={() => setIsOpenAvatarModal(true)}
                            className="w-[120px] h-[120px] lg:w-[210px] lg:h-[210px]"
                            style={{
                                cursor: 'pointer',
                                borderWidth: 4,
                                borderColor: 'white',
                                borderStyle: 'solid'
                            }}
                        />
                        <div className="absolute bottom-[0px] right-[-5px] lg:right-[5px] p-[4px] rounded-full bg-white cursor-pointer">
                            <div
                                className="w-[40px] h-[40px] bg-purple flex items-center justify-center rounded-full"
                                onClick={() => inputAvatarRef.current?.click()}
                            >
                                <ICCamera fill="white" />
                            </div>
                            <input
                                onChange={onUploaded}
                                accept="image/*"
                                type="file"
                                className="hidden"
                                ref={inputAvatarRef}
                            />
                            <UploadAvatar
                                file={file}
                                onClose={() => setFile(undefined)}
                            />
                            <AvatarModal
                                isOpen={isOpenAvatarModal}
                                onClose={() => setIsOpenAvatarModal(false)}
                                imgSrc={avatar}
                            />
                        </div>
                    </div>
                </div>
                <div className="lg:flex justify-between pt-1 lg:pt-8 w-[calc(100%-160px)] lg:w-[calc(100%-300px)]">
                    <div className="text-left">
                        <div className="lg:min-h-[37px] text-primary-bold text-[18px] font-bold lg:text-heading-5-bold truncate">{userInfo.name ?? userInfo.email}</div>
                        <div className="text-left lg:text-[18px] text-base text-med-em">@{userInfo.featuredName}</div>
                    </div>
                    {
                        showSaveButton && <div className="flex space-x-4 pt-2">
                            <span>
                                <HRButton btnSize="md" btnType="sub"
                                    onClick={onCancel}>Hủy bỏ</HRButton>
                            </span>
                            <span>
                                <HRButton
                                    onClick={onSave}
                                    btnSize="md"
                                >Lưu thay đổi</HRButton>
                            </span>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
