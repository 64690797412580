import React from "react";
import { useDispatch } from "react-redux";
import { useCandidateDetailsData } from "..";
import { inviteCandidateAPI } from "../../../api/candidate";
import { HRButton, IconButton } from "../../../components/button";
import { HRSelect } from "../../../components/form/select";
import { useNotification } from "../../../hooks/useNotification";
import { ICSendV3, ICArrowBack, ICArrowForward } from "../../../icons";
import { ICBack } from "../../../icons/back";
import { setLoading } from "../../../redux/slices/appInfo";
import { IInviteCandidate, TCandidate, TCandidateTestingData } from "../../testing/type";
import { useNavigation } from "../../../hooks/useNavigate";
import { Errors, isEqualError } from "../../../api/constants";
import classNames from 'classnames';
import styled from "styled-components";
import { useSticky } from "../../../hooks/useSticky";
import { ERROR_CODE } from "../../../utils/constants";
import { useScrollContent } from "../../../hooks/useScrollContent";
import { useMobile } from "../../../hooks/useDimensions";

type WrapperProps = {
    isMobile: boolean;
}
const Wrapper = styled(styled.div``) <WrapperProps>`
    position: ${props => props.isMobile ? 'relative' : 'sticky'};
    &.header-candidate-details-sticky {
        transition: transform .5s ease-out;
        top: 70px;
        left: 0;
        padding: 0;
        width: 100%;
        max-width: 100%;
        z-index: 99;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        box-sizing: border-box;
        padding-top: 4px;
        .none-sticky {
            display: none;
        }
        .sticky-content {
            max-width: 100%;
            padding: 8px 12px;
        }
    }
    &.nav-bar--hidden {
        transform: translateY(-1000%);
        box-shadow: none !important;
    }
`;

type THeaderCandidateDetailsProps = {
    myCandidate?: TCandidate,
    candidateDetails: TCandidateTestingData;
}

export const HeaderCandidateDetails: React.FC<THeaderCandidateDetailsProps> = ({
    candidateDetails,
    myCandidate,
}) => {
    const scrollData = useScrollContent();
    const { isSticky, element } = useSticky();
    const isMobile = useMobile();
    const { navigate, getBackUrl } = useNavigation();
    const dispatch = useDispatch();
    const { showSuccess, showError } = useNotification();
    const {
        mode,
        assessmentId,
        candidateIds,
        otherAssessments,
        setOtherAssessments,
    } = useCandidateDetailsData();
    const [assessmentSelected, setAssessmentSelected] = React.useState<string>();
    const [navClassList, setNavClassList] = React.useState<string[]>([]);

    const fullName = React.useMemo(() => {
        return `${candidateDetails?.candidate?.firstName || ''} ${candidateDetails?.candidate?.lastName || ''}`;
    }, [candidateDetails]);

    React.useEffect(() => {
        const _classList = [];
        if (scrollData.y > 60 && scrollData.y - scrollData.lastY > 0) {
            _classList.push("nav-bar--hidden");
        }
        setNavClassList(_classList);
    }, [scrollData.y, scrollData.lastY]);

    const onBackAction = React.useCallback(() => {
        const referrer = getBackUrl();
        let searchParams = new URLSearchParams();
        if (referrer) {
            const url = new URL(referrer);
            searchParams = new URLSearchParams(url.search);
        }

        if (assessmentId) {
            navigate({
                pathname: `/assessment/${assessmentId}/details`,
                search: searchParams.toString(),
            }, {
                state: {
                    hash: 'candidate-table',
                }
            });
        } else {
            navigate({
                pathname: `/my-candidate`,
                search: searchParams.toString()
            });
        }
    }, [navigate, getBackUrl, assessmentId]);

    const onNextAction = React.useCallback(() => {
        if (candidateIds) {
            const index = candidateIds.indexOf(candidateDetails?._id as string);
            if (index < candidateIds.length - 1) {
                navigate({
                    pathname: `/assessment/${assessmentId}/test/${candidateIds[index + 1]}/details`,
                });
            }
        }
    }, [navigate, assessmentId, candidateIds, candidateDetails]);

    const onPrevAction = React.useCallback(() => {
        if (candidateIds) {
            const index = candidateIds.indexOf(candidateDetails?._id as string);
            if (index > 0) {
                navigate({
                    pathname: `/assessment/${assessmentId}/test/${candidateIds[index - 1]}/details`,
                });
            }
        }
    }, [navigate, assessmentId, candidateIds, candidateDetails]);

    const inviteCandidateToOtherAssessment = React.useCallback(async () => {
        if (assessmentSelected) {
            try {
                dispatch(setLoading(true));
                const payload: IInviteCandidate = {
                    email: candidateDetails?.candidate?.email || myCandidate?.email || '',
                    firstName: candidateDetails?.candidate?.firstName || myCandidate?.firstName || '',
                    lastName: candidateDetails?.candidate?.lastName || myCandidate?.lastName || '',
                };
                await inviteCandidateAPI(assessmentSelected, payload);
                setOtherAssessments((prev) => {
                    return prev.filter((item) => item._id !== assessmentSelected);
                });
                setAssessmentSelected(undefined);
                showSuccess(`Đã mời ${payload.email} thành công`);
            } catch (error: any) {
                console.error(error);
                const errorCode = error?.response?.data?.errorCode || '';
                if (errorCode === ERROR_CODE.MAX_INVITE_CANDIDATE_PER_HOUR || isEqualError(error, Errors.MaxInviteCandidatePerHour)) {
                    showError("", "Bạn thực hiện thao tác quá nhanh. Vui lòng thử lại sau 1 giờ.");
                }
                else showError("Error", error?.response?.data?.errorMessage || error?.response?.statusText);
            }
            finally {
                dispatch(setLoading(false));
            }
        }
    }, [dispatch, setOtherAssessments, candidateDetails, myCandidate, assessmentSelected, showError, showSuccess]);

    return <Wrapper
        ref={element}
        isMobile={isMobile}
        className={
            classNames(
                [
                    "text-left lg-container z-[4] px-2",
                    {
                        "header-candidate-details-sticky shadow-e-03 box-border": isSticky && !isMobile,
                    },
                    ...navClassList,
                ],
            )
        }>
        <div className="lg-container lg:flex items-center justify-between sticky-content">
            <div className="grid grid-cols-[auto_1fr] gap-[16px] justify-start items-center">
                <div className="flex items-start">
                    {!isSticky && <IconButton btnType="sub" btnSize="sm"
                        onClick={onBackAction}
                        icon={<ICBack />}
                    />}
                    {isSticky && <HRButton btnSize="sm" btnType="sub" onClick={onBackAction}>
                        <div className="flex items-center gap-[8px]">
                            <ICBack />
                            <span className="text-body-medium text-high-em">
                                Trở về
                            </span>
                        </div>
                    </HRButton>}
                </div>
                {mode === 'assessment' && <div className="grid grid-cols-1 gap-[8px]">
                    <div className={classNames("lg:max-w-[500px] line-clamp-1 text-high-em", isSticky && !isMobile ? "text-standard-semi-bold" : "text-heading-5-bold")}>
                        {fullName}
                    </div>
                    <div className="grid grid-cols-1 gap-[16px] justify-start none-sticky text-sub-medium text-gray-600">
                        <span>{candidateDetails?.candidate?.email}</span>
                    </div>
                </div>}
                {mode === 'candidate' && <div className="grid grid-cols-1 gap-[8px]">
                    <div className={classNames("lg:max-w-[500px] line-clamp-1 text-high-em", isSticky && !isMobile ? "text-standard-semi-bold" : "text-heading-5-bold")}>
                        {myCandidate?.fullName}
                    </div>
                    <div className="text-sub-medium text-gray-600 grid grid-cols-1 gap-[16px] justify-start none-sticky">
                        <span>{myCandidate?.email}</span>
                    </div>
                </div>}
            </div>
            <div className="flex gap-[16px] justify-between items-center pt-2 lg:pt-0">
                <HRSelect
                    placeholder="Mời đến assessment khác"
                    options={otherAssessments.map((item) => ({
                        label: item.title,
                        value: item._id,
                    }))}
                    value={assessmentSelected}
                    style={{ width: '250px' }}
                    // open={true}
                    clearIcon={false}
                    onChange={(value) => setAssessmentSelected(value)}
                />
                <div>
                    {!isSticky && <HRButton
                        btnType="primary"
                        btnSize="sm"
                        onClick={inviteCandidateToOtherAssessment}
                    >
                        <div className="flex items-center gap-[10px]">
                            <ICSendV3 fill="white" width={18} height={18} />
                            <span>Mời</span>
                        </div>
                    </HRButton>}
                    {isSticky && <IconButton
                        btnType="primary"
                        btnSize="sm"
                        icon={<ICSendV3 fill="white" width={18} height={18} />}
                        onClick={inviteCandidateToOtherAssessment}
                    />}
                </div>
                {mode === 'assessment' && <div className="hidden lg:grid grid-cols-3 justify-center text-center items-center">
                    <HRButton
                        btnType="tertiary"
                        btnSize="sm"
                        onClick={onPrevAction}
                    >
                        <ICArrowBack />
                    </HRButton>
                    <span className="text-[14px] text-[#2E293D] font-medium">
                        {candidateIds?.indexOf(candidateDetails?._id as string) + 1}/{candidateIds.length}
                    </span>
                    <HRButton
                        btnType="tertiary"
                        btnSize="sm"
                        onClick={onNextAction}
                    >
                        <ICArrowForward />
                    </HRButton>
                </div>}
            </div>
        </div>
    </Wrapper>
}
