import { Progress } from "antd";
import React from "react";
import { ICCheckboxListLine, ICStopWatch } from "../../../icons";
import { HRButton } from "../../../components/button";
import styled from "styled-components";
import { TQuestionTesting } from "../type";
import { EQuestionType } from "../../create_test/tab/question/type";

const ProgressLine = styled(Progress)`
    .ant-progress-line {
        max-height: 6px !important;
    }
    .ant-progress-outer {
        border-radius: 144px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-progress-inner {
        border-radius: 144px !important;
        height: 6px;
    }
    .ant-progress-bg {
        height: 6px !important;
    }
`;

type TimerQuestionProps = {
    totalTimes: number | undefined;
    leftTimes: number | undefined;
    totalQuestion: number | undefined;
    isPinQuestionList: boolean;
    answers: any;
    questions: TQuestionTesting[];
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}
export const TimerQuestion: React.FC<TimerQuestionProps> = ({
    totalTimes = 0,
    leftTimes = 0,
    totalQuestion = 0,
    answers = [],
    questions = [],
    isPinQuestionList,
    setOpenDrawer,
}) => {
    const percent = React.useMemo(() => {
        // return Math.floor(((leftTimes) / totalTimes) * 100);
        return Math.floor(((totalTimes - leftTimes) / totalTimes) * 100);
    }, [leftTimes, totalTimes]);
    const [minutes, seconds] = React.useMemo(() => {
        const leftSeconds = leftTimes % 60;
        const leftMinutes = Math.floor(leftTimes / 60);
        return [leftMinutes, leftSeconds];
    }, [leftTimes]);

    const questionAnswered = React.useMemo(() => {
        return questions.filter((question, index) => {
            if (answers && (question.type === EQuestionType.SINGLE_CHOICE || question.type === EQuestionType.ESSAY || question.type === EQuestionType.SPEAKING )) {
                const isCorrect = Array.isArray(answers) && answers[index] === 0 ? true : Array.isArray(answers) && answers[index];
                return isCorrect;
            }
            if (question.type === EQuestionType.MULTIPLE_CHOICE && answers && Array.isArray(answers)) {
                return answers && Array.isArray(answers[index]) && answers[index] && answers[index]?.length === 0 ? false : Array.isArray(answers) && answers[index];
            }
            if(question.type === EQuestionType.GROUP && answers && Array.isArray(answers)) {
                return answers && Array.isArray(answers[index]) && answers[index] && answers[index]?.length === 0 ? false : Array.isArray(answers) && answers[index];
            }
            return false;
        })?.length;

    }, [questions, answers]);

    const questionAnsweredPercent = React.useMemo(() => {
        return Math.floor((questionAnswered / totalQuestion) * 100);
    }, [questionAnswered, totalQuestion]);

    return <div className="lg:flex shadow-e-01 rounded-[12px] bg-white">
        <div className="flex flex-row gap-[16px] items-center px-[16px] py-[8px] w-[190px]">
            <div>
                <Progress
                    percent={percent}
                    width={40}
                    trailColor="transparent"
                    strokeColor="#A797FF"
                    type="circle"
                    format={() => {
                        return <div className="flex justify-center items-center shadow-e-02 p-[4px] box-border overflow-hidden rounded-[50%]">
                            <ICStopWatch />
                        </div>
                    }}
                />
            </div>
            <div className="min-w-[90px] text-left text-heading-5-bold text-primary-bold">
                <span>{minutes}</span>
                <span>:</span>
                <span>{seconds?.toString()?.padStart(2, "0")}</span>
            </div>
        </div>
        <div className="flex flex-col px-[16px] py-[8px] gap-[8px] w-full lg:w-[300px]">
            <div className="flex flex-row justify-between items-center max-h-[24px] min-h-[24px]">
                <div className="text-sub text-high-em">{totalQuestion} câu hỏi</div>
                <div>
                    <HRButton btnSize="xs" btnType="sub" onClick={() => {
                        if (!isPinQuestionList) {
                            setOpenDrawer(true);
                        }
                    }}>
                        <div className="flex gap-[8px] items-center">
                            <ICCheckboxListLine />
                            <span className=" text-tiny-medium text-gray-700">Danh sách câu hỏi</span>
                        </div>
                    </HRButton>
                </div>
            </div>
            <div className="flex items-center justify-center max-h-[12px] min-h-[12px]">
                <ProgressLine
                    percent={questionAnsweredPercent}
                    showInfo={false}
                    style={{ width: "100%" }}
                    trailColor="var(--tc-surface-high)"
                    strokeColor="var(--tc-system-success-300)"
                    strokeLinecap="butt"
                />
            </div>
        </div>
    </div>
}
