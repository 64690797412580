import React from "react"
import debouce from 'lodash/debounce';
import { ISearchMyAssessment } from "../../type";
import { HRInput } from "../../../../components/form/input";
import { ICSearch } from "../../../../icons";
import { HRSelect } from "../../../../components/form/select";
import { ECandidateAssessmenStatus } from "../../../testing/type";
import { ScoreCandidate } from "./score";
import { Modal } from "antd";
import { HRButton } from "../../../../components/button";

const STATUS = [
    {
        label: "Tất cả",
        value: 'all',
    },
    {
        label: "Hoàn thành",
        value: "done",
    },
    {
        label: "Đã mời",
        value: "invited",
    },
    {
        label: "Chưa hoàn thành",
        value: "in_progress",
    }
];

type TFilterCandidateListProps = {
    onChangeFilter: (value: any) => void;
    filterParams: ISearchMyAssessment;
}
export const FilterCandidateList: React.FC<TFilterCandidateListProps> = ({
    onChangeFilter,
    filterParams
}) => {
    const [searchString, setSearchString] = React.useState<string>("");
    const [rangeScore, setRangeScore] = React.useState<[number, number]>([0, 100]);
    const [scoreText, setScoreText] = React.useState<string>("");
    const [showRangeScore, setShowRangeScore] = React.useState<boolean>(false);
   
    const handleChange = React.useCallback(($event: any) => {
        let value = $event.target.value;
        if (value.length > 50) {
            return;
        }
        setSearchString(value);
        const newParams = { ...filterParams, searchString: value, page: 1 };
        onSearchKeyword(newParams);
    }, [filterParams]);

    const onSearchKeyword = React.useMemo(() => {
        return debouce(onChangeFilter, 600);
    }, [onChangeFilter]);

    React.useEffect(() => {
        return () => {
            onSearchKeyword.cancel();
        }
    }, [onSearchKeyword]);
    React.useEffect(() => {
        if (filterParams) {
            setSearchString(filterParams.searchString ?? '');
        }
    }, [filterParams]);

    const onClearValue = React.useCallback(() => {
        setSearchString('');
        const newParams = { ...filterParams, searchString: '', page: 1 };
        onSearchKeyword(newParams);
    }, [setSearchString, onSearchKeyword, filterParams]);

    const onClearRangeScore = React.useCallback(() => {
        setRangeScore([0, 100]);
        setScoreText('');
        const newParams = { ...filterParams, fromScore: null, toScore: null, page: 1 };
        onChangeFilter(newParams);
    }, [setRangeScore, onChangeFilter, filterParams]);

    const onChangeRangeScore = React.useCallback(() => {
        const newParams = { ...filterParams, fromScore: rangeScore[0], toScore: rangeScore[1], page: 1 };
        onChangeFilter(newParams);
    }, [rangeScore, onChangeFilter, filterParams]);

    React.useEffect(() => {
        if (rangeScore[0] === 0 && rangeScore[1] === 100) {
            setScoreText('');
        } else {
            setScoreText(`${rangeScore[0]}% - ${rangeScore[1]}%`);
        }
    }, [rangeScore]);

    return <div className="flex flex-col items-start p-[16px]">
        <div className="grid grid-cols-1 lg:grid-cols-[1fr_257px_210px] gap-[16px] w-full text-left">
            <HRInput
                placeholder="Tìm kiếm ứng viên"
                value={searchString}
                prefix={<ICSearch />}
                label="Tìm kiếm"
                hasClearIcon={true}
                onClearValue={onClearValue}
                onChange={handleChange}
            />
            <div onClick={() => setShowRangeScore(true)}>
                <HRInput
                    placeholder="Tất cả"
                    value={scoreText}
                    label="Khoảng điểm"
                    forceDisable={true}
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'white',
                    }}
                    hasClearIcon={true}
                    onClearValue={(event: any) => {
                        if (event?.stopPropagation) {
                            event.stopPropagation();
                        }
                        onClearRangeScore();
                    }}
                />
            </div>
            <HRSelect
                label="Trạng thái"
                placeholder="Lựa chọn"
                className="w-full"
                options={STATUS}
                value={filterParams?.status || ECandidateAssessmenStatus.ALL}
                onChange={(value) => onChangeFilter({ status: value === 'all' ? null : value, page: 1 })}
            />
        </div>
        <Modal
            centered
            onCancel={() => setShowRangeScore(false)}
            open={showRangeScore}
            title={
                <div className="font-bold text-[18px]">Xem khoảng điểm của các ứng viên</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="secondary"
                        btnSize="sm"
                        onClick={() => {
                            onClearRangeScore();
                            setShowRangeScore(false);
                        }}>
                        Xóa
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onChangeRangeScore();
                            setShowRangeScore(false);
                        }}>
                        Xác nhận
                    </HRButton>
                </div>
            }>
                <ScoreCandidate value={rangeScore} setValue={setRangeScore} />
        </Modal>
    </div>
}
