import React from "react";
import { useCreateTestPageContext } from "../.."
import { HRButton, IconButton } from "../../../../components/button"
import { ICClose, ICTick, ICWarning } from "../../../../icons"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./editor.style.css";
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { isEmptyHTML, toolbar } from "./editor.config";
import { createDefaultQuestion, EQuestionType, TChoiceQuestion } from "./type";
import { Modal } from "antd";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import classNames from 'classnames'
import { getInnerText } from "./helper";
import { MAX_QUESTIONS_IN_GROUP } from "./contants";
import _ from "lodash";
import { useNotification } from "../../../../hooks/useNotification";
import { MAX_CONTENT_LENGTH } from "../../../../utils/constants";

type EssayEditorProps = {
    selectedQuestion: TChoiceQuestion | undefined
    questionType: EQuestionType.ESSAY
    onCancel: () => void
}
export const EssayEditor: React.FC<EssayEditorProps> = ({ selectedQuestion, questionType, onCancel }) => {
    const [question, setQuestion] = React.useState<TChoiceQuestion>(() => selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion)
    const { form, selectedSection, setShowAddQuestion, onCreatedQuestion } = useCreateTestPageContext();
    const [needCheck, setNeedCheck] = React.useState<boolean>(false);
    const {
        showSuccess
    } = useNotification()
    const [editorState, setEditorState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.content).contentBlocks))
    );
    const [editorReferenceContentState, setEditorReferenceContentState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.referenceContent as string).contentBlocks))
    );

    React.useEffect(() => {
        const q = selectedQuestion ?? createDefaultQuestion(questionType) as TChoiceQuestion
        setQuestion(q)
        setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.content).contentBlocks)))
        setEditorReferenceContentState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(q.referenceContent as string).contentBlocks)))
    }, [selectedSection, questionType, selectedQuestion]);

    const isDirty = React.useMemo(() => {
        if (!selectedQuestion) return getInnerText(question.content).replace(/\n/g, '') !== ""
            || getInnerText(question.referenceContent as string).replace(/\n/g, '') !== ""
        return question.content !== selectedQuestion?.content
            || question.referenceContent !== selectedQuestion?.referenceContent
    }, [question, selectedQuestion]);

    const onEditorStateChange = (editorState: EditorState) => {
        setEditorState(editorState);
        setQuestion({
            ...question,
            content: draftToHtml(convertToRaw(editorState.getCurrentContent()))
        })
    }

    const invalidMaxLengthEditorReferenceContent = React.useMemo(() => {
        const text = editorReferenceContentState?.getCurrentContent()?.getPlainText().length;
        if (text && text > MAX_CONTENT_LENGTH) {
            return true
        }
        return false;
    }, [editorReferenceContentState]);

    const invalidMaxLengthEditor = React.useMemo(() => {
        const text = editorState?.getCurrentContent()?.getPlainText().length;
        if (text && text > MAX_CONTENT_LENGTH) {
            return true
        }
        return false;
    }, [editorState]);


    const onEditorReferenceContentStateChange = (valueEditor: EditorState) => {
        setEditorReferenceContentState(valueEditor);
        setQuestion({
            ...question,
            referenceContent: draftToHtml(convertToRaw(valueEditor.getCurrentContent()))
        })
    }

    const isQuestionError = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.content) || invalidMaxLengthEditor);
    }, [needCheck, question.content, invalidMaxLengthEditor]);

    const isQuestionErrorForReference = React.useMemo(() => {
        return needCheck && (isEmptyHTML(question.referenceContent as string) || invalidMaxLengthEditorReferenceContent);
    }, [needCheck, question.referenceContent, invalidMaxLengthEditorReferenceContent]);

    const validationValues = (): boolean => {
        setNeedCheck(true);
        return isEmptyHTML(question.content) ||
            isEmptyHTML(question.referenceContent as string) ||
            invalidMaxLengthEditorReferenceContent;
    };

    // check data length editorState
    const editorStateDataLength = React.useMemo(() => {
        const numberFormat = new Intl.NumberFormat('en-US');
        const contentLength = editorState.getCurrentContent().getPlainText().length;
        return `${contentLength} / ${numberFormat.format(MAX_CONTENT_LENGTH)}`;
    }, [editorState]);

    const editorReferenceStateDataLength = React.useMemo(() => {
        const numberFormat = new Intl.NumberFormat('en-US');
        const contentLength = editorReferenceContentState.getCurrentContent().getPlainText().length;
        return `${contentLength} / ${numberFormat.format(MAX_CONTENT_LENGTH)}`;
    }, [editorReferenceContentState]);

    const onSave = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            setShowAddQuestion(false);
        }
    }

    const onSaveAndAddAnother = () => {
        if (!validationValues()) {
            onCreatedQuestion(question);
            showSuccess("Thành công", "Thêm mới câu hỏi thành công")
            setNeedCheck(false);
            const defaultQuestion = createDefaultQuestion(questionType) as TChoiceQuestion
            setQuestion(defaultQuestion)
            setEditorState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultQuestion.content).contentBlocks)))
            setEditorReferenceContentState(EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(defaultQuestion.referenceContent as string).contentBlocks)))
        }
    }
    const [showCancelModal, setShowCancelModal] = React.useState<boolean>(false)
    const onCancelAction = React.useCallback(() => {
        if (isDirty) {
            setShowCancelModal(true)
        } else {
            onCancel()
        }
    }, [isDirty, setShowCancelModal, onCancel]);

    const inactiveAddQuestion = () => {
        if (!selectedSection) {
            return false;
        }
        return selectedSection?.questions.length >= MAX_QUESTIONS_IN_GROUP;
    };

    const title = React.useMemo(() => {
        return editorState.getCurrentContent().getPlainText();
    }, [editorState]);

    return <div className="pb-16 space-y-8 text-left lg-container mx-auto">
        <div className="lg:flex items-center justify-between">
            <div>
                <div className="flex space-x-2 items-center font-bold text-[24px]" >
                    <IconButton btnType="sub" btnSize="sm" icon={<ICClose width={18} height={18} />} onClick={onCancelAction} />
                    <span className="lg:max-w-[500px] line-clamp-1">{title || 'Câu hỏi mới'}</span>
                </div>
                <span className="text-xs font-medium text-n-600 ml-12">
                    {form.getFieldValue("title")} / {selectedSection?.name ?? ""}
                </span>
            </div>
            <div className="hidden lg:flex space-x-4">
                <span>
                    <HRButton
                        btnType="sub"
                        btnSize="md"
                        onClick={onCancelAction}
                    >
                        <div className="flex justify-center">
                            Hủy bỏ
                        </div>
                    </HRButton>
                </span>
                {
                    !selectedQuestion && <span><HRButton
                        onClick={onSaveAndAddAnother}
                        btnSize="md"
                        btnType="sub"
                        disabled={inactiveAddQuestion()}
                    >
                        <div className="flex justify-center">
                            Lưu và tạo cái mới
                        </div>
                    </HRButton></span>
                }
                <span>
                    <HRButton
                        btnSize="md"
                        onClick={onSave}
                        className="w-[107px] h-[44px]"
                        disabled={inactiveAddQuestion() && !selectedQuestion}
                    >
                        <div className="flex space-x-2 justify-center">
                            <ICTick />
                            <span>{selectedQuestion ? "Cập nhật" : "Lưu"}</span>
                        </div>
                    </HRButton>
                </span>
            </div>
        </div>
        <div className="mt-8 p-2 lg:p-8 bg-white rounded-[10px] grid grid-cols-1 lg:grid-cols-2 gap-[38px]">
            <div className=" grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
                <div className="text-primary-bold text-standard-medium">Phần câu hỏi</div>
                <div className="space-y-3"
                    onContextMenu={(e) => {
                        // @ts-ignore
                        document.querySelector(".questions-editor")?.focus?.()
                    }}
                >
                    <Editor
                        stripPastedStyles={true}
                        toolbar={toolbar}
                        editorState={editorState}
                        toolbarClassName={classNames(isQuestionError ? "error" : "", "editor-toolbar")}
                        wrapperClassName={classNames(isQuestionError ? "error" : "", "questions-wrapper", "editor-wrapper")}
                        editorClassName={classNames(isQuestionError ? "error" : "", "questions-editor", "editor-editor")}
                        onEditorStateChange={onEditorStateChange}
                        placeholder="Nhập câu hỏi..."
                    />
                    <div className="noted text-med-em text-tiny-medium flex items-center justify-end">
                        <span>{editorStateDataLength}</span>
                    </div>
                    {
                        invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Bạn đã nhập quá số lượng ký tự cho phép!</span>
                        </div>
                    }
                    {
                        isQuestionError && !invalidMaxLengthEditor && <div className="flex items-center space-x-2">
                            <ICWarning fill="#F55858" width={18} height={18} />
                            <span className="text-body italic text-error-500">Xin hãy nhập câu hỏi</span>
                        </div>
                    }
                </div>
            </div>
            <div className="grid grid-cols-1 grid-rows-[auto_1fr] justify-start items-start gap-[16px]">
                <div className="text-primary-bold text-standard-medium">Nội dung tham khảo để chấm điểm</div>
                <Editor
                    stripPastedStyles={true}
                    toolbar={toolbar}
                    editorState={editorReferenceContentState}
                    toolbarClassName={classNames(isQuestionErrorForReference ? "error" : "", "editor-toolbar")}
                    wrapperClassName={classNames(isQuestionErrorForReference ? "error" : "", "questions-wrapper", "editor-wrapper")}
                    editorClassName={classNames(isQuestionErrorForReference ? "error" : "", "questions-editor", "editor-editor")}
                    onEditorStateChange={onEditorReferenceContentStateChange}
                    placeholder="Nhập nội dung tham khảo..."
                />
                <div className="noted text-med-em text-tiny-medium flex items-center justify-end">
                    <span>{editorReferenceStateDataLength}</span>
                </div>
                {
                    invalidMaxLengthEditorReferenceContent && <div className="flex items-center space-x-2">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">Bạn đã nhập quá số lượng ký tự cho phép!</span>
                    </div>
                }
                {
                    isQuestionErrorForReference && !invalidMaxLengthEditorReferenceContent && <div className="flex items-center space-x-2">
                        <ICWarning fill="#F55858" width={18} height={18} />
                        <span className="text-body italic text-error-500">Xin hãy nhập nội dung tham khảo</span>
                    </div>
                }
            </div>
        </div>
        <div className="block lg:hidden p-2">
            <span>
                <HRButton
                    btnSize="md"
                    onClick={onSave}
                    className="w-[107px] h-[44px]"
                    disabled={inactiveAddQuestion() && !selectedQuestion}
                >
                    <div className="flex space-x-2 justify-center">
                        <ICTick />
                        <span>{selectedQuestion ? "Cập nhật" : "Lưu"}</span>
                    </div>
                </HRButton>
            </span>
        </div>
        <Modal
            title="Thông báo"
            open={showCancelModal}
            centered={true}
            onCancel={setShowCancelModal.bind(null, false)}
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnSize="sm"
                        btnType="sub"
                        onClick={() => setShowCancelModal(false)}>
                        Không
                    </HRButton>
                    <HRButton btnSize="sm" onClick={onCancel}>
                        Có
                    </HRButton>
                </div>
            }
        >
            <div className="space-y-2">
                <div>Các thông tin nhập vào sẽ bị mất</div>
                <div>Bạn có chắc chắn muốn hủy bỏ?</div>
            </div>
        </Modal>
    </div>
}

