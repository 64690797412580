import { Col, Row } from "antd";
import React from "react";
import { useCandidateCreditPageContext } from ".";
import { QuantityPayment } from "./quantity";
import { formatMoney } from "../../utils";

export const ListPayment: React.FC = () => {
    const { candidatePayment, quantity, setQuantity, totalMoneyNotPaid } = useCandidateCreditPageContext();
    const originalPrice = candidatePayment?.price?.originalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const totalCandidateCredit = React.useMemo(() => {
        if (candidatePayment?.creditPerQuantity) {
            return candidatePayment?.creditPerQuantity * quantity;
        }
        return 0;
    }, [candidatePayment, quantity]);

    return <div className="flex flex-col w-full galg:p-4 py-4">
        <span className="text-title-bold text-high-em">Thông tin đơn hàng</span>
        <div className="flex flex-col w-full bg-white">
            <div className="flex w-full gap-[32px] lg:p-4 py-4 rounded-t-[8px] shadow-header-payment">
                <div className="flex flex-col gap-[5px]">
                    <span className="text-primary text-body-bold">Thêm lượt đánh giá</span>
                    <span className="text-med-em text-tiny">Được áp dụng cho mọi bài đánh giá</span>
                </div>
                <div className="flex bg-[#ECECED] h-wull w-[1px]"></div>
                <div className="flex gap-[32px]">
                    <div className="flex flex-col gap-[5px]">
                        <span className="text-tiny-bold text-med-em">Lượt đánh giá hiện có</span>
                        <span className="text-high-em text-body-bold">{candidatePayment?.remainingCandidateCredit}</span>
                    </div>
                </div>
            </div>
            <hr />
            <div className="block lg:hidden">
                <span className="text-sub-bold text-low-em">
                    LƯỢT ĐÁNH GIÁ TỐI THIỂU: <span className="text-primary">100</span>
                </span>
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-[450px_260px_1fr]">
                <div className="hidden lg:flex flex-col lg:p-4 py-4 pl-0">
                    <span className="text-sub-bold text-low-em">
                        LƯỢT ĐÁNH GIÁ TỐI THIỂU
                    </span>
                </div>
                <div className="flex flex-col lg:p-4 py-4 pl-0">
                    <span className="text-sub-bold text-low-em">
                        SỐ LƯỢNG
                    </span>
                </div>
                <div className="flex flex-col lg:p-4 py-4 pl-0 items-end">
                    <span className="text-sub-bold text-low-em">
                        ĐƠN GIÁ
                    </span>
                </div>
                <div className="hidden lg:flex flex-col lg:p-4 py-4 pl-0">
                    <span className="text-high-em text-body-medium">
                        {candidatePayment?.creditPerQuantity} lượt
                    </span>
                </div>
                <div className="flex flex-col lg:p-4 py-4 pl-0">
                    <span className="text-high-em text-sub-bold">
                        <QuantityPayment quantity={quantity} setQuantity={setQuantity} />
                    </span>
                </div>
                <div className="flex flex-col lg:p-4 py-4 pl-0 items-end">
                    <div className="text-high-em text-[14px] leading-[24px] font-medium">
                        <div className="flex items-start font-medium text-[14px] leading-[24px] text-high-em">
                            <div className="flex flex-col items-end">
                                {originalPrice && <div className="flex gap-[6px]">
                                    <span className="text-success-500 text-body-medium">Giảm {candidatePayment?.price?.discount}%</span>
                                    <div className="line-through text-[#B3B1B8]">
                                        <span>{originalPrice}</span>
                                        <span>{' '}{candidatePayment?.price?.unit}</span>
                                    </div>
                                </div>}
                                <div className="flex gap-[5px] text-high-em text-body-bold">
                                    <span>{candidatePayment?.price?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span>
                                    <span>{candidatePayment?.price?.unit}/lượt</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="flex flex-col items-end justify-end">
                <div className="max-w-[450px]">
                    <div className="flex flex-row lg:p-4 py-4 pb-0 pl-0 items-center justify-between galg:p-4 py-4 w-full">
                        <span className="text-left text-body-medium text-med-em lg:min-w-[150px]">
                            Tổng số lượt đánh giá
                        </span>
                        <span className="min-w-[200px] text-right text-high-em text-body-bold">{totalCandidateCredit}</span>
                    </div>
                    <div className="flex flex-row lg:p-4 py-4 pl-0 items-center justify-between galg:p-4 py-4 w-full">
                        <span className="text-body-medium text-med-em text-left lg:min-w-[150px]">
                            Tạm tính
                        </span>
                        <span className="min-w-[200px] text-right text-high-em text-body-bold">{formatMoney(totalMoneyNotPaid, candidatePayment?.price?.unit)}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}
