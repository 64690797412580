import { Button, Modal, Table, Typography } from "antd"
import { ICBack } from "../../../icons/back"
import { HRButton } from "../../../components/button"
import React from "react"
import { ICUploadV2 } from "../../../icons"
import { parse, unparse } from "papaparse";
import { useNotification } from "../../../hooks/useNotification"
import { isValidEmail } from "../../../utils"
import { nanoid } from "nanoid"
import _ from "lodash"
import { TCandidateRequest } from "../type"
import { useMobile } from "../../../hooks/useDimensions"
type InviteTableModalProps = {
    isOpen: boolean
    onClose: () => void,
    onInvite: (
        data: TCandidateRequest[]
    ) => void
}
type Data = {
    key: string;
    index: number,
    email: string,
    fullName: string,
}
const columns = [
    {
        title: 'STT',
        dataIndex: 'index',
        key: 'index',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
    },
    {
        title: 'Họ và tên',
        dataIndex: 'fullName',
        key: 'fullName',
    },
];

const SAMPLE_DATA = [
    {
        key: '1',
        index: 1,
        email: 'contact@husa.io',
        fullName: 'Nguyễn Văn A'
    }
]
const FORMAL_FIELDS = ['stt', 'email', 'fullName'];
function guestKeyFromCsvRow(
    sampleRows: Record<string, string>[],
    type: 'email' | 'fullName',
    fields: string[]
): string {
    switch (type) {
        case "email":
            return fields.find(field => {
                return sampleRows.every(row => isValidEmail(row[field]))
            }) || FORMAL_FIELDS[1];
        case "fullName":
            return fields.find(field => {
                return sampleRows.some(row => {
                    // Not empty string and not email and not contain only number
                    return row[field] && !isValidEmail(row[field]) && !/^\d+$/.test(row[field])
                })
            }) || FORMAL_FIELDS[2];
    }
}
export const InviteTableModal: React.FC<InviteTableModalProps> = ({
    isOpen,
    onClose,
    onInvite
}) => {
    const isMobile = useMobile();
    const { showError, showSuccess } = useNotification();
    const [data, setData] = React.useState<Data[]>(SAMPLE_DATA);
    const inputRef = React.useRef<HTMLInputElement>(null);
    const onChangeUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (!file) return;
        try {
            parse(file, {
                header: true,
                complete: (result: any) => {
                    try {
                        const fields = result.meta.fields as string[];
                        const data = _.chain(result.data as Record<string, string>[])
                            .map(row => {
                                return _.mapValues(row, value => value.trim());
                            })
                            .filter(row => {
                                // Filter out empty row
                                return !_.every(row, value => !value);
                            })
                            .value();
                        const sampleRows = data.slice(0, 10);
                        const emailKey = guestKeyFromCsvRow(sampleRows, 'email', fields);
                        const fullNameKey = guestKeyFromCsvRow(sampleRows, 'fullName', fields);
                        const dataSource = data.map((row, index) => ({
                            key: nanoid(),
                            index: index + 1,
                            email: row[emailKey],
                            fullName: row[fullNameKey]
                        }));
                        setData(dataSource);
                    } catch (e) {
                        console.warn('Error', e)
                        showError('Error', 'File không đúng định dạng')
                    }
                }
            });
        } catch (e) {
            console.warn('Error', e)
            showError('Error', 'File không đúng định dạng')
        } finally {
            // Clear input
            if (inputRef.current) {
                inputRef.current.value = '';
            }
        }
    };
    const onConfirmInvite = () => {
        onInvite(data.map(row => ({
            email: row.email,
            firstName: row.fullName
        })));
        onClose();
    }
    return <Modal
        open={isOpen}
        centered={true}
        footer={null}
        width={1140}
        onCancel={onClose}
        bodyStyle={{
            padding: isMobile ? '12px' : '32px',
        }}
        onOk={onConfirmInvite}
    >
        <div className="flex flex-col justify-start items-start gap-[6px]">
            <div>
                <Typography.Title level={4}
                    style={{
                        color: '#110C22',
                        fontSize: '24px',
                    }}
                    className="font-bold">
                    Upload danh sách ứng viên
                </Typography.Title>
                <span className="text-[10px]">
                    Lưu ý: File csv phải có định dạng như mẫu dưới đây
                </span>
            </div>
            <div className="w-full h-[400px] lg:h-[510px] overflow-y-auto">
                <Table
                    size="small"
                    dataSource={data}
                    columns={columns}
                    locale={{
                        emptyText: 'Không có dữ liệu'
                    }}
                    rowKey={(record) => record.key}
                />
            </div>
            <div className="lg:flex justify-between items-center w-full">
                <div className="w-full pb-4">
                    <Button onClick={() => inputRef.current?.click()}>
                        <div className="flex items-center space-x-2">
                            <ICUploadV2 fill="#000" />
                            <span>Upload file csv</span>
                        </div>
                    </Button>
                    <input
                        ref={inputRef}
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={onChangeUpload}
                    />
                </div>
                <div className="flex items-center justify-end w-full gap-[16px] w-full">
                    <span>
                        <HRButton
                            onClick={onClose}
                            btnType="warning"
                            btnSize="sm"
                        >
                            <div className="text-[14px] font-semibold flex items-center space-x-2 justify-center">
                                <ICBack height={16} width={16} fill="white" />
                                <span>Trở về</span>
                            </div>
                        </HRButton>
                    </span>

                    <span>
                        <HRButton
                            onClick={onConfirmInvite}
                            disabled={data.length === 0 || data === SAMPLE_DATA}
                            btnType="primary"
                            btnSize="sm"
                        >
                            <div className="text-[14px] font-semibold flex items-center space-x-2 justify-center">
                                <span>
                                    Xác nhận
                                </span>
                            </div>
                        </HRButton>
                    </span>
                </div>
            </div>
        </div>
    </Modal>
}