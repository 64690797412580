import { ContentState, EditorState, Editor as EditorDraft, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import React from "react";
import { EQuestionType, TChoiceQuestion, TQuestion } from "../../create_test/tab/question/type"
import { TestingAnswerWrapper } from "./answer";
import classNames from 'classnames';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { Iframe } from "../../../components/iframe";
import { MAX_CONTENT_LENGTH } from "../../../utils/constants";
import { toolbarNoLink } from "../../create_test/tab/question/editor.config";
import { GroupQuestionRender } from "./GroupQuestionRenderer";
import { SpeakingQuestionRenderer } from "./SpeakingQuestionRenderer";

export const toolbar = {
    options: ['inline', 'list', 'link'],
    inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ['bold', 'italic', 'underline', 'superscript', 'subscript'],
    },
    list: {
        options: ['unordered', 'ordered'],
    }
}

// Duplicate with src\pages\preview-testing\test\question.tsx
// TODO: Refactor
type QuestionRenderProps = {
    question: TQuestion;
    onChangeCorrectAnswer: (answer: undefined | number | number[] | string | string[]) => void;
    correctAnswer?: number | number[] | string | string[]
    currentQuestionIndex: number;
}
export const QuestionRender: React.FC<QuestionRenderProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
    currentQuestionIndex,
}) => {
    switch (question?.type) {
        case EQuestionType.SINGLE_CHOICE:
        case EQuestionType.MULTIPLE_CHOICE:
        case EQuestionType.ESSAY:
            return <ChoiceQuestionRender
                key={question.uuid}
                question={question}
                currentQuestionIndex={currentQuestionIndex}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={correctAnswer}
            />
        case EQuestionType.CODING:
            // TODO: Coding question render
            return <>Coding Question Render</>
        case EQuestionType.GROUP:
            return <GroupQuestionRender
                key={question.uuid}
                question={question}
                currentQuestionIndex={currentQuestionIndex}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={correctAnswer as number[] | string[]}
            />
        case EQuestionType.SPEAKING:
            return <SpeakingQuestionRenderer 
                key={question.uuid} 
                question={question} 
                currentQuestionIndex={currentQuestionIndex}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                recordUrl={question.recordFileUrl ?? correctAnswer as string}
            />
    }
}

type ChoiceQuestionRenderProps = {
    question: TChoiceQuestion;
    onChangeCorrectAnswer: (answer: undefined | number | number[] | string | string[]) => void;
    correctAnswer?: number | number[] | string | string[];
    currentQuestionIndex: number;
}
const ChoiceQuestionRender: React.FC<ChoiceQuestionRenderProps> = ({
    question,
    onChangeCorrectAnswer,
    correctAnswer,
    currentQuestionIndex,
}) => {
    const [editorAnswerState, setEditorAnswerState] = React.useState<EditorState>(
        EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(question?.answer || '').contentBlocks))
    );

    React.useEffect(() => {
        if (question.type === EQuestionType.ESSAY) {
            if (editorAnswerState.getSelection().getHasFocus()) {
                return;
            }
            const content = (correctAnswer || '') as string;
            setEditorAnswerState(
                EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(content).contentBlocks))
            );
        }
    }, [correctAnswer]);

    const hasValueEditorAnswerState = React.useMemo(() => {
        const content = editorAnswerState.getCurrentContent();
        const valueContent = content.getPlainText();
        const firstContentType = content.getBlockMap().first().getType();
        if (valueContent.length === 0) {
            if (['unstyled'].includes(firstContentType)) {
                return false;
            }
            return true;
        }
        return true;
    }
    , [editorAnswerState]);

    const onEditorStateChange = (editorState: EditorState) => {
        const text = editorState.getCurrentContent().getPlainText()
        const oldContentState = editorAnswerState.getCurrentContent().getPlainText();
        // const lines = text.split(/\n/);
        if (text.length > MAX_CONTENT_LENGTH) {
            const oldState = ContentState.createFromBlockArray(htmlToDraft(oldContentState).contentBlocks);
            setEditorAnswerState(EditorState.moveFocusToEnd(EditorState.push(editorAnswerState, oldState, 'insert-characters')));
            const answerQuestion = draftToHtml(convertToRaw(editorAnswerState.getCurrentContent()));
            onChangeCorrectAnswer(answerQuestion);
        } else {
            setEditorAnswerState(editorState);
            const answerQuestion = draftToHtml(convertToRaw(editorState.getCurrentContent()));
            onChangeCorrectAnswer(answerQuestion);
        }
        // else if (lines.length > 50) {
        //     const undo = EditorState.undo(editorState)
        //     setEditorAnswerState(undo)
        // }
    }

    return <div className="flex flex-col py-[32px] px-2 lg:px-[48px] rounded-[12px] bg-white shadow-e-03 box-border overflow-hidden gap-[56px]">
        <div className="w-full">
            <div className="pl-[24px] flex flex-col gap-[24px] border-l-[3px] border-[#C6BBFF]">
                <div className="text-standard-medium text-primary-bold">CÂU {currentQuestionIndex}</div>
                <Iframe content={question?.content as string || ''} />
            </div>
        </div>
        {[EQuestionType.SINGLE_CHOICE, EQuestionType.MULTIPLE_CHOICE].includes(question.type) && <div className="flex flex-col gap-[16px] w-full">
            <div className="text-standard-medium text-low-em">
                {
                    question.type === EQuestionType.SINGLE_CHOICE ? 'LỰA CHỌN MỘT ĐÁP ÁN' : 'LỰA CHỌN ĐÁP ÁN'
                }
            </div>
            <TestingAnswerWrapper
                question={question}
                onChangeCorrectAnswer={onChangeCorrectAnswer}
                correctAnswer={correctAnswer as number | number[]}
            />
        </div>}
        {question.type === EQuestionType.ESSAY && <div className="flex flex-col gap-[16px] w-full">
            <div className="text-standard-medium text-low-em">PHẦN TRẢ LỜI</div>
            <Editor
                stripPastedStyles={true}
                toolbar={toolbarNoLink}
                editorStyle={{ 
                    // height: '250px',
                    minHeight: '250px',
                    // overflow: 'auto',
                    // resize: 'vertical',
                    color: '#110C22',
                }}
                editorState={editorAnswerState}
                toolbarClassName={'editor-toolbar'}
                wrapperClassName={classNames("questions-wrapper", "editor-wrapper", "answer-essay-wrapper")}
                editorClassName={
                    classNames([
                        "questions-editor",
                        "editor-editor editor-essay-editor",
                        hasValueEditorAnswerState ? "has-value" : "no-value"
                    ])
                }
                onEditorStateChange={onEditorStateChange}
                placeholder="Nhập đáp án"
            />
        </div>}
    </div>
}
