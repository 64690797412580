import { Modal } from "antd"
import React from "react"
import { HRButton, IconButton } from "../../../../../../components/button"
import { ICChevronDown, ICDelete, ICDraggable, ICEdit, ICSmallClock } from "../../../../../../icons"
import { TAdditionalQuestion } from '../../../type';
import classNames from 'classnames'
import styled from 'styled-components';
import Highlighter from "react-highlight-words";
import { HRTooltip } from "../../../../../../components/tooltip"
import { htmlToText } from "../../../../../../utils/htmlToText"
import { AnswerWrapper } from "./editor"
import { EQuestionType, TChoiceQuestionType } from "../../../../../create_test/tab/question/type";

const Wrapper = styled.div`
    p {
        margin-bottom: 0;
    }
`;

type AssessmentQuestionRowProps = {
    question: TAdditionalQuestion,
    id: string,
    onDelete: (id: string) => void
    onUpdate: (id: string, type: TChoiceQuestionType) => void,
    onCollapsed: (id: string) => void
}
export const AssessmentQuestionRow = React.memo<AssessmentQuestionRowProps>(({
    question,
    id,
    onDelete,
    onUpdate,
    onCollapsed,
}) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);
    const onChangeCollapsed = React.useCallback(() => {
        onCollapsed && onCollapsed(id);
    }, [id, onCollapsed]);

    const questionTypeName = React.useMemo(() => {
        switch (question.type) {
            case EQuestionType.SINGLE_CHOICE:
                return "Single choice";
            case EQuestionType.MULTIPLE_CHOICE:
                return "Multiple choice";
            case EQuestionType.ESSAY:
                return "Essay";
            default:
                return "";
        }
    }, [question.type]);
    return <div className={classNames(!question.isActive ? "bg-n-150" : "",
        "group p-[8px] border-[1px] border-transparent hover:border-outline-high hover:shadow-e-03 rounded-[8px]",
    )}>
        <div className="flex items-center justify-between">
            <div className={classNames(!question.isActive ? "opacity-40" : "", "grid grid-cols-[auto_120px_1fr] gap-[8px] items-center")}>
                <span className="relative p-1 cursor-pointer opacity-100 lg:opacity-0 group-hover:opacity-100">
                    <HRTooltip content='Sắp xếp câu hỏi'
                        placement={'top'}
                        childNode={<IconButton
                            icon={<ICDraggable fill="#8D8A95" />}
                            btnType="tertiary"
                            btnSize="sm"
                        />}>
                    </HRTooltip>
                </span>
                <div>
                    <span className="px-[8px] py-[4px] rounded-[144px] text-sub-medium text-primary border border-solid border-outline-primary-low bg-surface-primary-accent-1">
                        {questionTypeName}
                    </span>
                </div>
                <Wrapper className="grid grid-cols-[auto_1fr] gap-[8px] items-center">
                    <span className="text-base font-bold pr-[60px]">
                        <div className="grid grid-cols-[auto_1fr] gap-[3px] items-center text-sub-medium text-high-em"><ICSmallClock /> {question?.duration} phút</div>
                    </span>
                    <HRTooltip
                        content={question.content}
                        childNode={(
                            <span className="line-clamp-1 w-full text-sub-medium text-high-em">
                                <Highlighter
                                    highlightClassName="text-highlight"
                                    searchWords={[`''}`]}
                                    autoEscape={true}
                                    textToHighlight={htmlToText(question.content)}
                                />
                            </span>
                        )}
                    >
                    </HRTooltip>
                </Wrapper>
            </div>
            <div className="flex">
                <div className="flex opacity-100 lg:opacity-0 group-hover:opacity-100">
                    <IconButton
                        icon={<ICDelete fill="#8D8A95" height={18} width={18} />}
                        btnType="tertiary"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(true)}
                    />
                    <IconButton
                        icon={<ICEdit fill="#8D8A95" height={18} width={18} />}
                        btnType="tertiary"
                        btnSize="sm"
                        onClick={() => onUpdate(id, question?.type)}
                    />
                </div>
                <IconButton
                    icon={<ICChevronDown stroke="#8D8A95" height={18} width={18} />}
                    btnType="tertiary"
                    btnSize="sm"
                    onClick={onChangeCollapsed}
                />
            </div>
        </div>
        {
            question?.isExpand && <QuestionExplanation question={question} />
        }
        <Modal
            centered
            onCancel={() => setShowDeleteModal(false)}
            open={showDeleteModal}
            title={
                <div className="font-bold text-[18px]">Xóa khỏi danh sách</div>
            }
            footer={
                <div className="grid grid-cols-[auto_auto] justify-end gap-[16px]">
                    <HRButton
                        btnType="sub"
                        btnSize="sm"
                        onClick={() => setShowDeleteModal(false)}>
                        Hủy bỏ
                    </HRButton>
                    <HRButton
                        btnSize="sm"
                        onClick={() => {
                            onDelete(id)
                            setShowDeleteModal(false)
                        }}>
                        Xóa
                    </HRButton>
                </div>
            }
        >
            <p>Bạn có chắc muốn xóa ra khỏi danh sách câu hỏi bổ sung?</p>
        </Modal>
    </div >
});

type QuestionExplanationProps = {
    question: TAdditionalQuestion,
}
const QuestionExplanation: React.FC<QuestionExplanationProps> = ({
    question
}) => {
    return <div className={classNames((question.type !== EQuestionType.ESSAY ? "grid-cols-2" : "grid-cols-1"), "grid gap-[24px] space-x-3 p-6")}>
        <div className="grid overflow-auto">
            <div className="text-standard-medium text-primary-bold">Câu hỏi</div>
            <span className="w-full h-min line-clamp-7 text-body text-high-em">
                <span dangerouslySetInnerHTML={{
                    __html: question.content
                }}></span>
            </span>
        </div>
        {
            question.type !== EQuestionType.ESSAY ? <div className="grid">
                <div className="text-standard-medium text-primary-bold">Đáp án</div>
                <AnswerWrapper question={question} viewOnly={true} />
            </div> : null
            //     <div className="flex flex-col gap-[8px]">
            //     <div className="text-standard-medium text-primary-bold">
            //         Gợi ý đánh giá
            //     </div>
            //     <div className="grid overflow-auto">
            //         <span className="w-full h-min line-clamp-7 text-body text-high-em">
            //             <span dangerouslySetInnerHTML={{
            //                 __html: question.referenceContent
            //             }}></span>
            //         </span>
            //     </div>
            // </div>
        }
    </div>
}
