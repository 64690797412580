import { Form, Input, Typography } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React from 'react';
import { ICCheckV2, ICClock, ICUserV2 } from '../../../../icons';
import { HRInput } from '../../../../components/form/input';
import { HRForm } from '../../../../components/form/form';
import { HRButton } from '../../../../components/button';
import { STEPS } from '../../type';
import { useTestingPageContext } from '../..';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useTesting } from '../../hooks/useTesting';
import { ApiInstance } from '../../../../api';
import { ErrorLink } from '../../../../components/errorLink';
import { useCandidateLayoutContext } from '../../../../layout/candidate';

export const ConfirmUserInvited: React.FC = () => {
    const [form] = useForm();
    const { companyProfile } = useCandidateLayoutContext();
    const { confirmInviteCadidate } = useTesting();
    const { url, assessmentDetails, setToken, setStep, setAssessmentDetails } = useTestingPageContext();
    const [hasErrorAssessment, setHasErrorAssessment] = React.useState(false);

    const logoCompany = React.useMemo(() => {
        return `${process.env.REACT_APP_S3_URL}/${companyProfile?.logo}`;
    }, [companyProfile]);

    const childForm = (
        <div className="grid gap-[16px] grid-cols-[1fr]">
            {assessmentDetails?.assessment?.activePassword && <div className="space-y-1">
                <label className="text-sm font-bold leading-[17px] space-x-1">
                    <span>Mật khẩu</span>
                    <span className="text-red-500 text-xs">(*)</span>
                </label>
                <Form.Item
                    name="assessmentPassword"
                    rules={[
                        {
                            required: true,
                            message: "Bạn chưa điền Mật khẩu",
                        },
                    ]}
                >
                    <Input.Password
                        placeholder="Mật khẩu"
                        prefix={<ICClock />}
                        maxLength={50}
                        style={{
                            width: '100%',
                            height: '44px',
                            borderRadius: '4px',
                            border: '1px solid #BEC0CA',
                        }}
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
            </div>}
            <Form.Item name="lastName">
                <HRInput label="Họ" prefix={<ICUserV2 fill="#BEC0CA" width={24} height={24} />} placeholder="Nhập họ của bạn" maxLength={20} />
            </Form.Item>
            <Form.Item
                name="firstName"
                rules={[
                    {
                        required: true,
                        message: "Bạn chưa điền Tên",
                    },
                    {
                        whitespace: true,
                        message: "Bạn chưa điền Tên",
                    },
                ]}
            >
                <HRInput label="Tên" prefix={<ICUserV2 fill="#BEC0CA" width={24} height={24} />} placeholder="Nhập tên của bạn" maxLength={50} isRequired={true} />
            </Form.Item>
        </div>
    );

    React.useEffect(() => {
        form.setFieldsValue({
            lastName: assessmentDetails?.candidate?.lastName,
            firstName: assessmentDetails?.candidate?.firstName,
        });
    }, [assessmentDetails, form]);

    const onSubmit = React.useCallback(() => {

        form.validateFields().then((results: any) => {
            confirmInviteCadidate(url, results, setAssessmentDetails, setHasErrorAssessment).then((res) => {
                if (res?.data) {
                    const testCandidateToken = res.data?.testCandidateToken;
                    // Add bearer token to socket
                    ApiInstance.setToken(testCandidateToken);
                    setToken(testCandidateToken);
                    setStep(STEPS.ABILITIES_CHECK);
                }
            }).catch((err) => console.warn(err));
        }).catch((err) => console.warn(err));
    }, [url, form, setStep, setToken, setAssessmentDetails, confirmInviteCadidate]);

    return hasErrorAssessment ? <ErrorLink /> :
        <div className="flex bg-white lg:max-w-[562px] rounded-[12px] px-4 lg:px-8 py-[54px] shadow-e-03">
            <div className="grid grid-cols-1 gap-[32px] items-start justify-start">
                <div className="grid grid-cols-1 gap-[8px]">
                    <Typography.Title level={5} style={{
                        color: '#110C22',
                        fontWeight: 700,
                        marginBottom: 0,
                        fontSize: '18px',
                    }}>Xin hãy xác nhận danh tính</Typography.Title>
                    <span className="text-standard text-high-em">
                        Vui lòng nhập mật khẩu để bắt đầu làm bài đánh giá.
                    </span>
                </div>
                <div className="grid grid-cols-1">
                    <HRForm form={form} name="receingLink" childNode={childForm} />
                </div>
                <div>
                    <HRButton
                        onClick={onSubmit}
                        btnType="primary"
                        style={{
                            backgroundColor: companyProfile?.color?.backgroundColor,
                            borderColor: companyProfile?.color?.backgroundColor,
                            color: companyProfile?.color?.fontColor || "#fff",
                        }}
                    >
                        <div className="flex space-x-2 items-center justify-center">
                            <ICCheckV2 width={24} height={24} fill={companyProfile?.color?.fontColor || "#fff"} />
                            <span>Xác nhận</span>
                        </div>
                    </HRButton>
                </div>
            </div>
        </div>
}
