import React from "react";
import { HRButton } from "../../../components/button";
import { ICAdd, ICWarning } from "../../../icons";
import { useNavigate } from "react-router-dom";
import { useAssementDetailsData } from ".";
import classNames from 'classnames';

export const CandidateCreditInfo: React.FC = () => {
    const navigate = useNavigate();
    const { myCandidateCreditInfo } = useAssementDetailsData();

    return <div className="flex items-center gap-4 justify-center lg:h-[48px] bg-surface-low rounded-[8px] py-[4px] lg:pl-8 pr-[16px] py-1">
        <div className="lg:flex flex-row gap-[24px] items-center justify-center">
            <div className="lg:grid grid-cols-1">
                <span className="text-body-medium text-high-em">
                    {myCandidateCreditInfo?.totalUsedCount} lượt đánh giá &nbsp;
                </span>
                <span className="text-sub text-med-em">
                    Đã dùng
                </span>
            </div>

            <div className="hidden bg-outlines-med h-[24px] w-[1px]">

            </div>

            <div className="grid grid-cols-1 center pt-1">
                <div className="flex gap-[4px] items-center">
                    {myCandidateCreditInfo?.remainingCandidateCredit <= 10 && <span>
                        <ICWarning width={18} height={18} fill={myCandidateCreditInfo?.remainingCandidateCredit === 0 ? '#F03D3D' : '#E09400'} />
                    </span>}
                    <span className={classNames([
                        'text-[14px] font-medium leading-[24px] text-high-em',
                        myCandidateCreditInfo?.remainingCandidateCredit === 0 && 'text-[#F03D3D]',
                        myCandidateCreditInfo?.remainingCandidateCredit > 0 && myCandidateCreditInfo?.remainingCandidateCredit <= 10 && 'text-[#E09400]',
                    ])}>
                        {myCandidateCreditInfo?.remainingCandidateCredit} lượt đánh giá &nbsp;
                        <span className="inline lg:hidden text-[12px] font-normal leading-[16px] text-[#676472]">Hiện có</span>
                    </span>
                </div>
                <div className="hidden lg:flex items-center px-2 lg:px-0">
                    <span className="text-[12px] font-normal leading-[16px] text-[#676472]">
                        Hiện có
                    </span>
                </div>
            </div>
        </div>
        <div className="flex flex-row items-center justify-center">
            <HRButton 
                onClick={(event) => {
                    event.stopPropagation();
                    navigate("/candidate-credit/payment");
                }}
                btnType="sub"
                btnSize="sm"
                style={{
                    width: myCandidateCreditInfo?.remainingCandidateCredit === 0 ? '132px' : '40px',
                    backgroundColor: myCandidateCreditInfo?.remainingCandidateCredit === 0 ? '#FFF9F9' : (myCandidateCreditInfo?.remainingCandidateCredit <= 10 ? '#FFFAED' : 'white'),
                }}
            >
                <div className="flex items-center justify-center gap-[8px]">
                    <span>
                        <ICAdd width={18} height={18} 
                            stroke={myCandidateCreditInfo?.remainingCandidateCredit === 0 ? '#F03D3D' : (myCandidateCreditInfo?.remainingCandidateCredit <= 10 ? '#E09400' : '#4F4B5C')} 
                        />
                    </span>
                    {myCandidateCreditInfo?.remainingCandidateCredit === 0 && <span className={classNames([
                        'text-[14px] font-bold leading-[24px] text-[#F03D3D]'
                    ])}>
                        Mua thêm
                    </span>}
                </div>
            </HRButton>
        </div>
            
    </div>
}
